import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import BottomSheet from '@components/modal/bottomSheet/BottomSheet';
import Portal from '@components/portal';
import LoginButton from './LoginButton';
import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';
import { useNavigate } from 'react-router-dom';
import { useLayoutEffect } from 'react';

interface Props {
  onClose: () => void;
  handleLogin: (type: 'G' | 'A' | 'K' | 'N') => void;
}

const AnotherLoginBottomSheet = ({ onClose, handleLogin }: Props) => {
  const navigate = useNavigate();

  const onClickLoginInquiry = () => {
    window.open('https://mynormal.notion.site/d32c03a623474ab4a2f2f98a22243ba4', '_blank');
    onClose();
  };

  const onClickKetoLogin = () => {
    navigate('/ketoLogin');
    onClose();
  };

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  return (
    <Portal>
      <DimmedWrapper onClose={onClose}>
        <BottomSheet onClose={onClose}>
          <BottomSheet.Contents>
            <Wrapper>
              <ButtonField>
                <LoginButton
                  platform="naver"
                  onClick={() => {
                    onClose();
                    handleLogin('N');
                  }}
                />
                <LoginButton platform="keto" onClick={onClickKetoLogin} />
              </ButtonField>
              <Text onClick={onClickLoginInquiry}>로그인 문의</Text>
            </Wrapper>
          </BottomSheet.Contents>
        </BottomSheet>
      </DimmedWrapper>
    </Portal>
  );
};

export default AnotherLoginBottomSheet;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 28px 28px;
`;

const ButtonField = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const Text = styled.p`
  margin-top: 24px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.5px;
  text-align: center;
  color: ${COLORS.PRIMITIVES_GRAY_550};
`;
