import useMyRoomPurchasedItemListQuery from '@pages/my/hooks/useMyRoomPurchasedItemListQuery';
import MyRoomItemListSkeleton from '@pages/my/components/skeleton/MyRoomItemListSkeleton';
import styled from 'styled-components';
import ItemCard from './ItemCard';
import NonePage from '@components/Atoms/NonePageInBox';
import { StoreMyRoom } from '@models/store';
import useItemListScrollRestoration from '@pages/my/hooks/useItemListScrollRestoration';

interface Props {
  addItem: (item: StoreMyRoom) => void;
}

const PurchasedItemList = ({ addItem }: Props) => {
  const { myRoomList, isLoading } = useMyRoomPurchasedItemListQuery();
  const { wrapperRef } = useItemListScrollRestoration(isLoading, 'purchased');

  if (isLoading) {
    return <MyRoomItemListSkeleton />;
  }

  if (!myRoomList || myRoomList?.length === 0) {
    return <NonePage text="보유 중인 아이템이 없어요" type="normal" />;
  }

  return (
    <Wrapper ref={wrapperRef}>
      {myRoomList?.map((item) => (
        <ItemCard
          key={`My_${item.room_item_idx}`}
          name={item.name}
          onClickCard={() => addItem(item)}
          roomItemIdx={item.room_item_idx}
          thumbnail={item.img_thumb}
          salePercent={item.sale_percent}
          salePrice={item.sale_price}
          liked={item.liked}
          isPurchased
          isLimitedType={item.acquisition_type === 'limited'}
        />
      ))}
    </Wrapper>
  );
};

export default PurchasedItemList;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 9.5px;
  row-gap: 30px;
  padding: 0px 20px;
  padding: 57px 20px 30px;
  overflow-y: auto;
`;
