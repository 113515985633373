interface Props<T> {
  selectedId: string | null;
  items?: Array<T>;
}

function useItemSort<T extends { id: string }>({ selectedId, items }: Props<T>) {
  const sortItems = (direction: 'up' | 'down') => {
    if (!items || !selectedId) return;

    const copyItems = [...items];
    const selectedIndex = copyItems.findIndex((v) => v.id === selectedId);

    if (selectedIndex === -1) return copyItems;

    const swap = (i: number, j: number) => {
      const temp = copyItems[i];
      copyItems[i] = copyItems[j];
      copyItems[j] = temp;
    };

    if (direction === 'up' && selectedIndex > 0) {
      swap(selectedIndex, selectedIndex - 1);
    }

    if (direction === 'down' && selectedIndex < copyItems.length - 1) {
      swap(selectedIndex, selectedIndex + 1);
    }

    return copyItems;
  };

  const onClickSortUp = () => sortItems('up');
  const onClickSortDown = () => sortItems('down');

  return {
    onClickSortUp,
    onClickSortDown,
  };
}

export default useItemSort;
