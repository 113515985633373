import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { padNumber } from '@modules/date';

dayjs.extend(isoWeek);

// UTC 시간 배열을 현재 시간과의 차이를 기준으로 정렬
export function sortByClosestToCurrentTime<
  T extends { [key: string]: string | number | Date | any },
>(utcTimeArray: T[], key: keyof T) {
  const currentTime = new Date();

  return utcTimeArray.sort((a, b) => {
    const timeA = new Date(a[key]);
    const timeB = new Date(b[key]);

    // 현재 시간과의 차이 계산 (분 단위로 변환)
    const diffA = Math.abs((Number(currentTime) - Number(timeA)) / (1000 * 60));
    const diffB = Math.abs((Number(currentTime) - Number(timeB)) / (1000 * 60));

    // 차이가 적은 순으로 정렬 (오름차순)

    return diffA - diffB;
  });
}

/**
 * @param targetDate D-day를 목적으로 하는 날짜
 * @param expireDate 기준일 (따로 넘겨주지 않으면, default: 오늘)
 */
export const handleDueDate = (
  targetDate: string,
  expireDate?: string | undefined,
): number | 'expired' => {
  const target = dayjs(targetDate);
  const expire = dayjs(expireDate) ?? dayjs();
  const isExpired = target.isBefore(expire);

  if (isExpired) {
    return 'expired';
  } else {
    return dayjs(targetDate).diff(expire, 'day') + 1;
  }
};

/**
 * @param targetDate D-day를 목적으로 하는 날짜
 */
export const getDday = (targetDate: string) => {
  const today = dayjs().tz('Asia/Seoul').format('YYYY-MM-DD');
  const diffEndCount = dayjs(targetDate).tz('Asia/Seoul').diff(today, 'day');

  return padNumber(diffEndCount, 2);
};

/**
 * @param targetDate D-day를 목적으로 하는 날짜
 */
export const getDdayNumber = (targetDate: string) => {
  const today = dayjs();
  const endDate = dayjs(targetDate).utc();

  // 종료일과 오늘 날짜의 차이를 계산
  return endDate.startOf('day').diff(today.startOf('day'), 'day');
};

/**
 * @description param data가 오늘, 이번주, 이번달에 해당 되는지 return
 */
export const handleLatestDate = (date: string) => {
  const isToday = dayjs().isSame(dayjs(date), 'day');
  const isThisWeek =
    dayjs().isoWeek() === dayjs(date).isoWeek() && dayjs().year() === dayjs(date).year();
  const isThisMonth = dayjs().isSame(dayjs(date), 'month');

  return { isToday, isThisWeek, isThisMonth };
};

/**
 *
 * @returns 현재 달이 이번 년도의 몇번째 분기인지 return
 */
export const getCurrentQuarter = (date: string) => {
  const now = dayjs(date).tz('Asia/Seoul');
  const currentMonth = now.month() + 1;
  return Math.ceil(currentMonth / 3);
};

export const isLimitedPeriodExpired = (limited_start: string, limited_end: string): boolean => {
  const today = dayjs();

  // 'Z'를 제거하여 KST(한국 표준시)로 처리
  const limitedStartKST = limited_start?.replace('Z', '');
  const limitedEndKST = limited_end?.replace('Z', '');

  const startDate = dayjs(limitedStartKST);
  const endDate = dayjs(limitedEndKST);

  // 오늘 날짜가 시작 날짜 이전이거나 종료 날짜 이후인 경우 만료됨
  if (today.isBefore(startDate) || today.isAfter(endDate)) {
    return true;
  }

  // 그 외의 경우(오늘 날짜가 시작과 종료 사이)는 만료되지 않음
  return false;
};

export const getLimitedPeriodMessage = (limited_end: string) => {
  const today = dayjs();
  const endDate = dayjs(limited_end).utc();

  // 종료일과 오늘 날짜의 차이를 계산
  const diff = endDate.startOf('day').diff(today.startOf('day'), 'day');

  if (diff > 0) {
    return `<span>${diff}<span>일 남음`;
  } else if (diff === 0) {
    return '오늘까지';
  } else {
    return '';
  }
};
