import { useQuery } from 'react-query';
import { getUserInfo } from '@apis/profileApi';
import { getCookie } from '@utils/cookie';
import { ACCESS_TOKEN } from '@shared/link';

const useUserInfoQuery = (enabled = true, suspense = true) => {
  const userKey = getCookie(ACCESS_TOKEN);
  const { data, ...queryState } = useQuery(['getUserInfo', userKey], () => getUserInfo(), {
    suspense,
    refetchOnWindowFocus: false,
    enabled,
    retry: false,
    staleTime: Infinity,
  });

  const memberIdx = data?.member_idx ?? 0;

  const getIsBlockingById = (userIdx: number) => data?.ban_list.some((idx) => idx === userIdx);

  return {
    data,
    memberIdx,
    getIsBlockingById,
    ...queryState,
  };
};

export default useUserInfoQuery;
