export const CalenderKey = {
  ALL: 'calender',
  MONTHLY_CALENDER: 'monthlyCalenderQuery',
  MONTHLY_STICKER: 'monthlyStickerQuery',
} as const;

export const StatisticsKey = {
  ALL: 'statistics',
  CHART: 'chartQuery',
  SUB_INFO: 'subInfoQuery',
} as const;

export const anonymityKeys = {
  RECOMMEND: 'recommend',
} as const;
