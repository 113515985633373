import NonePage from '@components/Atoms/NonePageInBox';
import MyRoomItemListSkeleton from '@pages/my/components/skeleton/MyRoomItemListSkeleton';
import useMyRoomItemListQuery from '@pages/my/hooks/useMyRoomItemListQuery';
import ItemCard from './ItemCard';
import styled from 'styled-components';
import { myRoomCurrentMainCategoryState, myRoomCurrentSubCategoryState } from '@states/myRoomState';
import { useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { StoreMyRoom } from '@models/store';
import useItemListScrollRestoration from '@pages/my/hooks/useItemListScrollRestoration';
import { isLimitedPeriodExpired } from '@utils/timeUtil';
import useToastModal from '@hooks/useToastModal';

interface Props {
  addItem: (item: StoreMyRoom) => void;
}

const CategoryItemList = ({ addItem }: Props) => {
  const [ref, inView] = useInView();
  const { openModal: openToast } = useToastModal();
  const currentMainCategory = useRecoilValue(myRoomCurrentMainCategoryState);
  const currentSubCategory = useRecoilValue(myRoomCurrentSubCategoryState);
  const { myRoomList, isLoading, fetchNextPage } = useMyRoomItemListQuery();
  const { wrapperRef } = useItemListScrollRestoration(isLoading, 'categories');

  const handleAddItem = (item: StoreMyRoom) => {
    if (isLimitedPeriodExpired(item.limited_start, item.limited_end) && item.purchased === 0) {
      openToast({ children: <p>판매 종료된 상품이에요</p> });
      return;
    }

    addItem(item);
  };

  useEffect(() => {
    const isMore = myRoomList?.length % 10 === 0;
    if (isMore && inView && !isLoading) fetchNextPage();
  }, [inView, myRoomList?.length, isLoading]);

  if (isLoading) {
    return <MyRoomItemListSkeleton />;
  }

  if (!myRoomList || myRoomList?.length === 0) {
    return <NonePage text="등록된 상품이 없어요" type="normal" />;
  }

  return (
    <Wrapper ref={wrapperRef}>
      {myRoomList.map((item) => (
        <ItemCard
          key={`${currentMainCategory}_${currentSubCategory}_${item.room_item_idx}`}
          name={item.name}
          onClickCard={() => handleAddItem(item)}
          roomItemIdx={item.room_item_idx}
          thumbnail={item.img_thumb}
          salePercent={item.sale_percent}
          salePrice={item.sale_price}
          liked={item.liked}
          isPurchased={item.purchased === 1}
          isLimitedType={item.acquisition_type === 'limited'}
        />
      ))}
      <div ref={ref} />
    </Wrapper>
  );
};

export default CategoryItemList;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 9.5px;
  row-gap: 30px;
  padding: 0px 20px;
  padding: 57px 20px 30px;
  overflow-y: auto;
`;
