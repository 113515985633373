import { atom } from 'recoil';

import {
  AnonymityCategoryNumber,
  BoardCategoryNumber,
  KetogramCategoryNumber,
} from 'types/community';

const communityModalState = atom<boolean>({
  key: '#communityModalState',
  default: false,
});

const boardSelectedIndexState = atom<BoardCategoryNumber>({
  key: `#boardSelectedIndexState`,
  default: 99,
});

const anonymitySelectedIndexState = atom<AnonymityCategoryNumber>({
  key: `#anonymitySelectedIndexState`,
  default: 99,
});

const ketogramSelectedIndexState = atom<KetogramCategoryNumber>({
  key: `#ketogramSelectedIndexState`,
  default: 99,
});

const communityTypeState = atom<'popular' | 'board' | 'anonymity' | 'ketogram' | ''>({
  key: '#communityTypeState',
  default: '',
});

const anonymityBlockState = atom<Map<number, boolean>>({
  key: '#AnonymityBlockState',
  default: new Map(),
});

export {
  communityModalState,
  boardSelectedIndexState,
  anonymitySelectedIndexState,
  communityTypeState,
  ketogramSelectedIndexState,
  anonymityBlockState,
};
