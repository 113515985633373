import { MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';
import LEVEL from 'styles/constants/_levels';

interface Props {
  level?: number;
  onClose?: () => void;
  children?: ReactNode;
  className?: string;
}
const DimmedWrapper = ({ level = LEVEL.MODAL_BASIC, onClose, children, className }: Props) => {
  const handleClose = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (onClose) {
      onClose();
    }
  };

  return (
    <Container level={level} className={className}>
      <Dimmed onClick={handleClose} />
      {children && children}
    </Container>
  );
};

const Container = styled.div<{ level: number }>`
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 428px;
  height: 100%;
  z-index: ${(props) => props.level};
`;

const Dimmed = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`;

export default DimmedWrapper;
