import { useEffect, useState } from 'react';
import styled from 'styled-components';
import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import ApplePointImage from '@assets/images/rewards/applePoint.png';
import { COLORS } from '@styles/constants/_colors';
import LEVEL from '@styles/constants/_levels';
import Portal from '@components/portal';
import useModal from '@hooks/useModal';

interface Props {
  price: number;
  description: string;
  onConfirm: () => void | Promise<void>;
  onClose?: () => void;
}

const StorePurchaseModal = ({ onClose, onConfirm, price, description }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { closeModal } = useModal();

  const handleConfirm = async () => {
    await onConfirm();
    closeModal(StorePurchaseModal);
  };

  const handleClose = () => {
    onClose?.();
    closeModal(StorePurchaseModal);
  };

  useEffect(() => {
    setTimeout(() => setIsOpen(true), 100);
  }, []);

  return (
    <Portal>
      <DimmedWrapper level={LEVEL.FORCE_MODAL} onClose={handleClose}>
        <ModalField isOpen={isOpen}>
          <ContentField>
            <TitleUnit>
              사과 <span>{price}개</span>로{'\n'}구매하시겠어요?
            </TitleUnit>

            <ApplePointImageUnit src={ApplePointImage} alt="point image" />

            <ActionBox>
              <CancelButtonUnit onClick={handleClose}>아니오</CancelButtonUnit>
              <ConfirmButtonUnit onClick={handleConfirm}>네 좋아요!</ConfirmButtonUnit>
            </ActionBox>

            <DescriptionUnit>{description}</DescriptionUnit>
          </ContentField>
        </ModalField>
      </DimmedWrapper>
    </Portal>
  );
};

export default StorePurchaseModal;

const ModalField = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 50%;
  top: ${({ isOpen }) => (isOpen ? '50%' : '100%')};
  transform: ${({ isOpen }) => (isOpen ? 'translate(-50%, -50%)' : 'translate(-50%, 100%)')};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 428px;
  padding: 0 30px;
  overflow: hidden;
  transition: all 200ms ease;
`;

const ContentField = styled.div`
  background-color: ${COLORS.WHITE};
  padding: 38px 30px 33px;
  border-radius: 26px;
`;

const TitleUnit = styled.h1`
  color: ${COLORS.BLACK};
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 145%;
  white-space: pre;
  margin: 0;
  font-family: 'Cafe24 Ssurround';
  > span {
    color: ${COLORS.RED};
  }
`;

const ApplePointImageUnit = styled.img`
  width: 100%;
  margin-top: 10px;
`;

const ActionBox = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 13px;
`;

const CancelButtonUnit = styled.button`
  flex: 1;
  width: 100%;
  height: 50px;
  padding: 17px 18px 16px 19px;
  border-radius: 120px;

  font-family: 'Cafe24 Ssurround';
  font-size: 14px;
  font-weight: 700;
  color: ${COLORS.PRIMITIVES_GRAY_550};
  background-color: ${COLORS.PRIMITIVES_GRAY_200};
`;

const ConfirmButtonUnit = styled.button`
  flex: 2;
  width: 100%;
  height: 50px;
  border-radius: 120px;
  padding: 17px 18px 16px 19px;

  font-family: 'Cafe24 Ssurround';
  font-size: 14px;
  font-weight: 700;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.BLACK};
`;

const DescriptionUnit = styled.p`
  text-align: center;
  font-family: 'Noto Sans KR';
  margin-top: 19px;

  color: ${COLORS.PRIMITIVES_GRAY_600};
  font-size: 13px;
  font-weight: 500;
  line-height: 120%;

  span {
    font-family: Campton;
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.5px;
  }
`;
