import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import BottomSheet from '@components/modal/bottomSheet/BottomSheet';
import Portal from '@components/portal';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import { isPurchaseLoadingState } from '@pages/apple/states';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import { renderProduct } from '@structures/pointPurchase/renderProduct';
import { COLORS } from '@styles/constants/_colors';
import { useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import ProductCard from './ProductCard';
import { useEffect, useState } from 'react';
import BottomButton from '@components/BottomButton';
import ShortageImage from '@assets/images/rewards/shortage.png';
import Spinner from '@components/Spinner';
import usePostMessageReceiver from '@hooks/usePostMessageReceiver';
import useModal from '@hooks/useModal';
import RewardsModal from '@pages/apple/components/RewardsModal';
import useToastModal from '@hooks/useToastModal';
import LEVEL from '@styles/constants/_levels';
import BottomTwoButton from '@components/BottomTwoButton';

interface Props {
  isShortage: boolean;
  amountRemaining: number;
  currentBalance: number;
  onAdBrix?: (pointType: string) => void;
}

const PointPurchaseBottomSheet = ({
  isShortage,
  amountRemaining,
  currentBalance,
  onAdBrix,
}: Props) => {
  const { openModal, closeModal } = useModal();
  const { openModal: openToast } = useToastModal();
  const queryClient = useQueryClient();
  const [isPurchaseLoading, setIsPurchaseLoading] = useRecoilState(isPurchaseLoadingState);
  const [selectedProductName, setSelectedProductName] = useState(renderProduct[4].name);
  const { memberIdx } = useUserInfoQuery();

  const onClose = () => {
    closeModal(PointPurchaseBottomSheet);
  };

  const onClickProduct = (name: string) => {
    setSelectedProductName(name);
  };

  const requestPurchase = () => {
    sendReactNativeMessage({
      type: 'iapRequestPurchase',
      payload: {
        productId: selectedProductName,
        memberIdx,
      },
    });
    setIsPurchaseLoading(true);
  };

  useEffect(() => {
    sendReactNativeMessage({ type: 'iapAddListener', payload: { memberIdx } });
    return () => {
      sendReactNativeMessage({ type: 'iapRemoveListener' });
    };
  }, []);

  usePostMessageReceiver((event: any) => {
    const { type, data } = JSON.parse(event.data);
    switch (type) {
      case 'iapFinishedTransaction': {
        if (!data) return;
        setIsPurchaseLoading(false);
        closeModal(PointPurchaseBottomSheet);
        openModal(RewardsModal, {
          completeMessage: '구매 완료!',
          title: `사과 ${data.amount}개 획득!`,
          bottomSubText: '구매 내역은 ‘내 사과’에서 확인 가능!',
          onClose: () => {
            closeModal(RewardsModal);
          },
        });
        queryClient.invalidateQueries({ queryKey: ['point'], exact: false });
        onAdBrix?.(`사과 ${data.amount}개`);
        return;
      }
      case 'iapError': {
        setIsPurchaseLoading(false);
        const isFailed = !['E_USER_CANCELLED', 'E_DEVELOPER_ERROR'].includes(data);
        if (isFailed) openToast({ children: <p>결제에 실패했어요</p> });
        return;
      }
    }
  });

  return (
    <Portal>
      <DimmedWrapper level={LEVEL.FORCE_MODAL} onClose={onClose}>
        <Spinner onSpinnerState={isPurchaseLoading} zIndex={99999} />
        <BottomSheet containerPadding="12px 0px 0px 0px" onClose={onClose}>
          <BottomSheet.Headers>
            <Padding />
          </BottomSheet.Headers>
          <BottomSheet.Contents>
            <ContentField height={isShortage ? 'calc(70vh + 40px)' : 'auto'}>
              {isShortage && (
                <>
                  <Image src={ShortageImage} />
                  <ShortageText>
                    사과 <span>{amountRemaining ?? 0}개</span>가 부족해요
                  </ShortageText>
                  <Text>
                    현재 사과 <span>{currentBalance}</span>개 보유 중<span>!</span>
                  </Text>
                </>
              )}
              <ListBox padding={isShortage ? '0px 20px 52px' : '16px 20px'}>
                {renderProduct.map((v) => (
                  <ProductCard
                    key={v.name}
                    item={v}
                    onClick={() => onClickProduct(v.name)}
                    isSelected={selectedProductName === v.name}
                  />
                ))}
              </ListBox>
            </ContentField>
          </BottomSheet.Contents>
          <BottomSheet.Buttons>
            <ButtonBox>
              {isShortage ? (
                <BottomTwoButton
                  left={{
                    text: '나중에',
                    color: 'gray',
                    onClick: onClose,
                  }}
                  right={{
                    text: '충전하기',
                    color: 'black',
                    onClick: requestPurchase,
                  }}
                  padding="0px"
                />
              ) : (
                <BottomButton color="black" onClickEvent={requestPurchase}>
                  충전하기
                </BottomButton>
              )}
            </ButtonBox>
          </BottomSheet.Buttons>
        </BottomSheet>
      </DimmedWrapper>
    </Portal>
  );
};

export default PointPurchaseBottomSheet;

const ContentField = styled.div<{ height: string }>`
  width: 100%;
  height: ${({ height }) => height};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

const Image = styled.img`
  width: 100px;
  margin-top: 16px;
  margin-bottom: 10px;
  object-fit: contain;
`;

const Padding = styled.div`
  z-index: 10;
  width: 100%;
  min-height: 12px;
  background-color: ${COLORS.WHITE};
  border-radius: 120px;
`;

const ListBox = styled.div<{ padding: string }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 11px;
  padding: ${({ padding }) => padding};
`;

const ButtonBox = styled.div`
  padding: 12px 20px;
  background-color: ${COLORS.WHITE};
`;

const ShortageText = styled.p`
  color: ${COLORS.BLACK};
  text-align: center;
  font-family: 'Cafe24 Ssurround';
  font-size: 20px;
  font-weight: 700;
  line-height: 145%;
  letter-spacing: -0.5px;

  span {
    color: ${COLORS.RED};
  }
`;

const Text = styled.p`
  color: ${COLORS.PRIMITIVES_GRAY_600};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.5px;
  margin-top: 7px;
  margin-bottom: 36px;

  > span {
    font-size: 15px;
  }
`;
