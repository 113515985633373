import { NoResultContainer } from './styles';
import { NoResultIcon, WinkIcon } from 'assets/svgs';
import { cx } from 'styles';

interface Props {
  text: string;
  type?: 'normal' | 'comingSoon' | 'ban' | 'public';
}

const NonePage = ({ text, type }: Props) => {
  return (
    <NoResultContainer className={cx([type])}>
      {type === 'public' ? <WinkIcon /> : <NoResultIcon />}
      <p>{text}</p>
    </NoResultContainer>
  );
};

export default NonePage;
