import SpeechBubble from '@components/Atoms/bubble/SpeechBubble';
import { PointAppleIcon } from '@assets/svgs';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';

interface Props {
  percent: number;
  price: number;
}

const SalePrice = ({ percent, price }: Props) => (
  <PriceBox>
    {percent > 0 && (
      <BubbleBox>
        <SpeechBubble padding="3px 11px 2px 12px" backgroundColor="#F55320" tailType="triangle">
          <SaleUnit>
            {percent}
            <div>%</div>
          </SaleUnit>
        </SpeechBubble>
      </BubbleBox>
    )}
    <PriceUnit>
      <PointAppleIcon width={26} height={26} />
      <span>{price}</span>
    </PriceUnit>
  </PriceBox>
);

const SaleUnit = styled.div`
  display: flex;
  color: ${COLORS.WHITE};
  text-align: right;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.5px;

  > div {
    font-size: 12px;
    font-weight: 800;
    margin-top: 1px;
  }
`;

const PriceBox = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 113px;
  padding-right: 12px;
`;

const BubbleBox = styled.div`
  position: absolute;
  top: -21px;
`;

const PriceUnit = styled.div`
  display: flex;
  gap: 6px;
  color: ${COLORS.BLACK};
  font-size: 18px;
  font-weight: 800;
  line-height: 120%;
  letter-spacing: -0.5px;

  > span {
    margin-top: 4px;
  }
`;

export default SalePrice;
