export const mySubCategory = [
  '전체',
  '말풍선',
  '벽지',
  '바닥',
  '가전&가구',
  '사물&소품',
  '캐릭터',
  '식물&자연',
  '음식',
  '배경효과',
];
