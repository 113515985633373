import React from 'react';
import styled from 'styled-components';
import RoomDetailView from '@pages/apple/store/components/RoomDetailView';
import { useMutation, useQueryClient } from 'react-query';
import useToastModal from '@hooks/useToastModal';
import useGetPointQuery from '@pages/apple/hooks/useGetPointQuery';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { myRoomDetailState } from '@states/StoreState';
import { COLORS } from '@styles/constants/_colors';
import useModal from '@hooks/useModal';
import StorePurchaseModal from '@components/modal/StorePurchaseModal';
import Portal from '@components/portal';
import LEVEL from '@styles/constants/_levels';
import PointPurchaseBottomSheet from '@components/modal/PointPurchaseBottomSheet';
import { postMyRoomItemListPurchase } from '@apis/myRoomApi';
import { uniqueCartRoomItemsFilterState, uniqueCartRoomItemsState } from '@states/myRoomState';
import { timeZoneState } from '@pages/fasting/new/state';
import useAdBrix from '@hooks/adBrix/useAdBrix';
import { isLimitedPeriodExpired } from '@utils/timeUtil';

const RoomItemDetailModal = () => {
  const { sendAdBrixData } = useAdBrix();
  const queryClient = useQueryClient();
  const timezone = useRecoilValue(timeZoneState);
  const product = useRecoilValue(myRoomDetailState);
  const [uniqueCartRoomItems, setUniqueCartRoomItems] = useRecoilState(uniqueCartRoomItemsState);
  const setUniqueCartRoomItemsFilter = useSetRecoilState(uniqueCartRoomItemsFilterState);

  const { openModal, closeModal } = useModal();
  const { openModal: openToast } = useToastModal();

  const { memberIdx } = useUserInfoQuery();
  const { data: pointInfo } = useGetPointQuery(memberIdx);
  const { mutate: purchaseMutate } = useMutation({
    mutationFn: postMyRoomItemListPurchase,
    onSuccess: () => {
      setUniqueCartRoomItemsFilter('구매');
      setUniqueCartRoomItems((prev) => prev.filter((item) => product.id === item.id));
      sendAdBrixData('customEvent', {
        eventKey: 'complete_purchase_dp_item',
        property: {
          item_title: product.name,
        },
      });
      openToast({ children: <p>상품 구매를 완료했어요</p> });
      closeModal(RoomItemDetailModal);
      queryClient.invalidateQueries({ queryKey: ['point'], exact: false });
    },
    onError: () => {
      openToast({ children: <p>상품 구매에 실패했어요</p> });
      closeModal(RoomItemDetailModal);
    },
  });

  const handleModalBackClick = () => {
    closeModal(RoomItemDetailModal);
  };

  const handlePurchaseClick = () => {
    if (!pointInfo) return;
    if (isLimitedPeriodExpired(product.limited_start, product.limited_end)) {
      openToast({ children: <p>판매 종료된 상품이에요</p> });
      return;
    }

    openModal(StorePurchaseModal, {
      price: product.sale_price,
      description: '구매한 아이템으로 꾸민 방은 저장 가능!',
      onConfirm: () => {
        if (product.sale_price > pointInfo.balance) {
          openModal(PointPurchaseBottomSheet, {
            amountRemaining: product.sale_price - pointInfo.balance,
            currentBalance: pointInfo.balance,
            isShortage: true,
            onAdBrix: (pointType: string) => {
              sendAdBrixData('customEvent', {
                eventKey: 'complete_purchase_dp_point',
                property: {
                  point_type: pointType,
                },
              });
            },
          });
          return;
        }

        purchaseMutate({
          memberIdx,
          room_item_idxes: uniqueCartRoomItems
            .filter((item) => product.id === item.id)
            .map((item) => Number(item.room_item_idx)),
          timezone,
        });
      },
    });
  };

  return (
    <Portal>
      <Wrapper>
        <RoomDetailView onBackPress={handleModalBackClick} onPurchaseClick={handlePurchaseClick} />
      </Wrapper>
    </Portal>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  max-width: 428px;
  height: 100dvh;
  background-color: ${COLORS.WHITE};
  z-index: ${LEVEL.MODAL_HEADER};
`;

export default RoomItemDetailModal;
