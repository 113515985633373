import Portal from '@components/portal';
import LEVEL from '@styles/constants/_levels';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const RoundCheckMotion = () => {
  return (
    <Portal>
      <Container>
        <motion.svg
          width="90"
          height="90"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          animate={svgAnimate}
          transition={svgTransition}
        >
          <motion.circle fill="#007AFF" opacity="0.75" cx="45" cy="45" r="45" />
          <motion.path
            d="M29.6 44.5L39.6 54L60.6 35"
            stroke="white"
            strokeWidth="5.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            animate={checkboxAnimate}
            transition={checkboxTransition}
          />
        </motion.svg>
      </Container>
    </Portal>
  );
};

const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 100%;
  max-width: 428px;
  height: 100vh;

  pointer-events: none;
  z-index: ${LEVEL.SECURITY_MODAL};
`;

const svgAnimate = {
  y: [150, 0, 0, 0, 0, 0, 0, 0, 0, 150],
  opacity: [0, 1, 1, 1, 1, 1, 1, 1, 1, 0],
  scale: [0.5, 0.9, 1.5, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 0.9, 0.5],
};

const svgTransition = {
  type: 'spring',
  stiffness: 50,
  duration: 1.8,
  times: [0, 0.1, 0.18, 0.3, 0.4, 0.55, 0.6, 0.87, 0.9, 1],
};

const checkboxAnimate = {
  opacity: [0, 1, 1, 0],
  pathLength: [0, 1, 1, 0],
};

const checkboxTransition = {
  type: 'tween',
  delay: 0.5,
  duration: 0.9,
  times: [0, 0.3, 0.8, 1],
};

export default RoundCheckMotion;
