import { EditDeleteIcon, EditWriteIcon } from '@assets/svgs/_Icons';
import { COLORS } from '@styles/constants/_colors';
import styled from 'styled-components';

interface Props {
  type: string;
  isSelected?: boolean;
  width: number;
  height: number;
  onRemove?: () => void;
  onWrite?: () => void;
}

const SelectionBox = ({ type, isSelected, width, height, onRemove, onWrite }: Props) => {
  const isSpeechBubble = type === '말풍선';
  const wrapperWidth = isSpeechBubble ? width + 22 : width;
  const wrapperHeight = isSpeechBubble ? height + 20 : height;
  const transform = isSpeechBubble ? 'translate(-11px, -12px)' : 'none';

  return (
    <Wrapper
      isSelected={isSelected}
      width={wrapperWidth}
      height={wrapperHeight}
      transform={transform}
    >
      {isSelected && (
        <>
          <TopLeftCircle />
          <TopRightCircle />
          <BottomLeftCircle />
          <BottomRightCircle />
        </>
      )}
      {isSelected && !!onRemove && (
        <TopRightCircle top={-12} right={-12.5} size={24} onClick={onRemove}>
          <EditDeleteIcon />
        </TopRightCircle>
      )}
      {isSelected && isSpeechBubble && (
        <TopRightCircle top={-12} right={-44.5} size={24} onClick={onWrite}>
          <EditWriteIcon />
        </TopRightCircle>
      )}
    </Wrapper>
  );
};

export default SelectionBox;

const Wrapper = styled.div<{
  isSelected?: boolean;
  width: number;
  height: number;
  transform: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  box-shadow: ${({ isSelected }) =>
    isSelected
      ? `0 0 0 1px ${COLORS.WHITE}, 0px 1px 7px 0px #00000025, inset 0px 1px 7px 0px #00000025`
      : 'none'};
  z-index: 1000;
  transform: ${({ transform }) => transform};
`;

const CornerCircle = styled.div<{ isSelected?: boolean }>`
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${COLORS.WHITE};
  box-shadow: 0px 1px 7px 0px #00000040;
`;

const TopLeftCircle = styled(CornerCircle)`
  top: -3.5px;
  left: -3.5px;
`;

const TopRightCircle = styled(CornerCircle)<{ top?: number; right?: number; size?: number }>`
  top: ${({ top }) => (top ? top : -3.5)}px;
  right: ${({ right }) => (right ? right : -3.5)}px;
  width: ${({ size }) => (size ? size : 7)}px;
  height: ${({ size }) => (size ? size : 7)}px;
`;

const BottomLeftCircle = styled(CornerCircle)`
  bottom: -3.5px;
  left: -3.5px;
`;

const BottomRightCircle = styled(CornerCircle)`
  bottom: -3.5px;
  right: -3.5px;
`;
