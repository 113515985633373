import { getPoint } from '@apis/pointApi';
import { useQuery } from 'react-query';

const useGetPointQuery = (memberIdx: number) => {
  return useQuery({
    queryKey: ['point', memberIdx],
    queryFn: () => getPoint(memberIdx),
    suspense: true,
  });
};

export default useGetPointQuery;
