import { getWeight } from '@apis/exercise';
import { useQuery } from 'react-query';

interface Props {
  memberIdx: number;
  createdAt: string;
}

const useGetWeightQuery = ({ memberIdx, createdAt }: Props) => {
  return useQuery(['#weight', memberIdx, createdAt], () => getWeight({ memberIdx, createdAt }), {
    enabled: !!memberIdx,
  });
};

export default useGetWeightQuery;
