import { PointAppleLeftIcon } from '@assets/svgs';
import { GraySmileIcon } from '@assets/svgs/_Icons';
import LikeIcon from '@assets/svgs/_Icons/LikeIcon';
import useAdBrix from '@hooks/adBrix/useAdBrix';
import { StoreMyRoom } from '@models/store';
import useMyRoomLike from '@pages/apple/store/hooks/useMyRoomLike';
import { COLORS } from '@styles/constants/_colors';
import CDNURLConvert from '@utils/CDNUrlConvert';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import styled from 'styled-components';
import Limited from '@assets/images/png/limited.png';

interface Props {
  onClickCard: () => void;
  roomItemIdx: number;
  name: string;
  thumbnail: string;
  salePercent: number;
  salePrice: number;
  isPurchased?: boolean;
  isLikedItemList?: boolean;
  refetch?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<StoreMyRoom[], unknown>>;
  liked: number | null;
  isLimitedPeriodExpired?: boolean;
  isLimitedType?: boolean;
}

const ItemCard = ({
  onClickCard,
  roomItemIdx,
  name,
  thumbnail,
  salePercent,
  salePrice,
  isPurchased,
  isLikedItemList,
  refetch,
  liked,
  isLimitedPeriodExpired,
  isLimitedType,
}: Props) => {
  const { sendAdBrixData } = useAdBrix();
  const { isLike, onLikeClick, removeLike } = useMyRoomLike();

  const handleLikeClick = (roomItemIdx: number, name: string) => {
    sendAdBrixData('customEvent', {
      eventKey: 'click_room_editor_like',
    });

    if (isLikedItemList) {
      removeLike(roomItemIdx).then(() => {
        refetch?.();
      });
    } else {
      onLikeClick(roomItemIdx, name);
    }
  };

  return (
    <Wrapper>
      <ImgBox onClick={onClickCard}>
        {isLimitedType && <LimitedImg src={Limited} alt="limited_img" />}
        {liked !== null && (
          <LikeButton
            onClick={(e) => {
              e.stopPropagation();
              handleLikeClick(roomItemIdx, name);
            }}
          >
            <LikeIcon active={isLike(roomItemIdx)} size="s" width={22} height={22} />
          </LikeButton>
        )}
        <img className="thumbnail" src={CDNURLConvert(thumbnail)} alt="item thumbnail" />
        {isLimitedPeriodExpired && !isPurchased && (
          <LimitedDim>
            <LimitedText>판매 종료</LimitedText>
          </LimitedDim>
        )}
      </ImgBox>
      {isPurchased ? (
        <Row gap={5}>
          <GraySmileIcon />
          <PurchasedText>보유 중</PurchasedText>
        </Row>
      ) : (
        <Row gap={2}>
          {salePercent !== 0 && <SalePrice>{salePercent}%</SalePrice>}
          <PointAppleLeftIcon />
          <PriceText>{salePrice}</PriceText>
        </Row>
      )}
    </Wrapper>
  );
};

export default ItemCard;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
  width: 100%;
`;

const ImgBox = styled.div`
  position: relative;
  border-radius: 12px;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;

  .thumbnail {
    border-radius: 12px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const LikeButton = styled.button`
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 10;
`;

const LimitedImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 63px;
  height: 63px;
  z-index: 1;
`;

const LimitedDim = styled.div`
  background-color: rgba(0, 0, 0, 0.44);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const LimitedText = styled.p`
  font-family: Cafe24 Ssurround;
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: -0.5px;
  text-align: center;
  color: ${COLORS.WHITE};
`;

const Row = styled.div<{ gap?: number }>`
  display: flex;
  align-items: center;
  column-gap: ${({ gap }) => (gap ? gap : 0)}px;
`;

const PurchasedText = styled.p`
  color: ${COLORS.PRIMITIVES_GRAY_500};
  font-size: 13px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.5px;
`;

const PriceText = styled.p`
  color: ${COLORS.BLACK};
  font-family: Campton;
  font-size: 14px;
  font-weight: 600;
  line-height: 90%;
  letter-spacing: -0.5px;
  margin-top: 2px;
`;

const SalePrice = styled.p`
  color: ${COLORS.RED};
  font-family: Campton;
  font-size: 14px;
  font-weight: 600;
  line-height: 90%;
  letter-spacing: -0.5px;
  margin-top: 3px;
  margin-right: 3px;
`;
