import Portal from '@components/portal';
import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import BottomSheet from '@components/modal/bottomSheet/BottomSheet';
import BottomButton from '@components/BottomButton';
import styled from 'styled-components';
import TitleAndCloseHeader from '@components/modal/bottomSheet/TitleAndCloseHeader';
import TimePicker from './TimePicker';
import { useState } from 'react';
import { TimeStateType } from '@models/fasintg';

interface Props {
  onClose: () => void;
  onClickConfirm: (pickerTime: TimeStateType) => void;
  initialTimeForm: TimeStateType;
}

const TimePickerBottomSheet = ({ onClose, onClickConfirm, initialTimeForm }: Props) => {
  const [pickerTime, setPickerTime] = useState<TimeStateType>(initialTimeForm);

  return (
    <Portal>
      <DimmedWrapper onClose={onClose}>
        <BottomSheet onClose={onClose} showPanel={false}>
          <BottomSheet.Headers>
            <TitleAndCloseHeader onClose={onClose} title="단식 시작 시간" />
          </BottomSheet.Headers>
          <BottomSheet.Contents>
            <TimePicker pickerTime={pickerTime} setPickerTime={setPickerTime} />
          </BottomSheet.Contents>
          <BottomSheet.Buttons>
            <ButtonBox>
              <BottomButton
                isDisabled={
                  Number(pickerTime.hour) === 0 ||
                  pickerTime.hour === '' ||
                  pickerTime.minute === ''
                }
                color="black"
                onClickEvent={() => onClickConfirm(pickerTime)}
              >
                완료
              </BottomButton>
            </ButtonBox>
          </BottomSheet.Buttons>
        </BottomSheet>
      </DimmedWrapper>
    </Portal>
  );
};

export default TimePickerBottomSheet;

const ButtonBox = styled.div`
  padding: 12px 20px;
`;
