import styled from 'styled-components';
import BottomButton from './BottomButton';
import { PlusIcon, TrashIcon } from '@assets/svgs/_Icons';
import { COLORS } from '@styles/constants/_colors';

type ColorType = 'blue' | 'black' | 'disabled' | 'gray' | 'red';

const iconObject = {
  plus: <PlusIcon />,
  trash: <TrashIcon />,
} as const;
interface Props {
  padding?: string;
  left: {
    onClick: () => void;
    icon?: keyof typeof iconObject;
    color?: ColorType;
    text?: string;
    disabled?: boolean;
  };
  right: {
    onClick: () => void;
    color?: ColorType;
    text?: string;
    disabled?: boolean;
  };
  isActive?: boolean;
}

const BottomTwoButton = ({ left, right, padding }: Props) => {
  return (
    <Wrapper padding={padding}>
      <LeftButtonBox>
        <BottomButton
          color={left?.color ?? 'blue'}
          isDisabled={left.disabled}
          onClickEvent={() => left?.onClick()}
        >
          {left.icon && iconObject[left.icon]}
          {left?.text ?? '추가'}
        </BottomButton>
      </LeftButtonBox>
      <RightButtonBox>
        <BottomButton
          color={right?.color ?? 'black'}
          isDisabled={right.disabled}
          onClickEvent={() => right?.onClick()}
        >
          {right?.text ?? '기록 완료'}
        </BottomButton>
      </RightButtonBox>
    </Wrapper>
  );
};

export default BottomTwoButton;

const Wrapper = styled.div<{ padding?: string }>`
  display: flex;
  width: 100%;
  padding: ${({ padding }) => (padding ? padding : '12px 12px 0')};
  column-gap: 9px;
  background-color: ${COLORS.WHITE};
`;

const LeftButtonBox = styled.div`
  display: flex;
  flex-basis: 31%;

  svg {
    transform: translateY(1px);
  }
`;

const RightButtonBox = styled.div`
  display: flex;
  flex-basis: 69%;
`;
