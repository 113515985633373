interface Props {
  type: 'default' | 'triangle';
  bgColor: string;
}

const Tail = ({ type, bgColor }: Props) => {
  const tail = {
    default: <DefaultTail bgColor={bgColor} />,
    triangle: <TriangleTail bgColor={bgColor} />,
  }[type];
  return tail;
};

export default Tail;

const DefaultTail = ({ bgColor }: Pick<Props, 'bgColor'>) => {
  return (
    <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.48711 0.000152379C1.21651 1.28149 0.869402 2.90199 0.619088 3.98894C0.147538 6.03656 2.28007 4.51152 3.59215 3.46036C4.49696 2.73548 6.98814 1.00462 8.44751 0L1.48711 0.000152379Z"
        fill={bgColor}
      />
    </svg>
  );
};

const TriangleTail = ({ bgColor }: Pick<Props, 'bgColor'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="6" viewBox="0 0 13 6" fill="none">
      <mask
        id="mask0_8705_3922"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="13"
        height="6"
      >
        <rect width="13" height="6" fill={bgColor} />
      </mask>
      <g mask="url(#mask0_8705_3922)">
        <rect
          width="9.03541"
          height="9.03541"
          rx="2"
          transform="matrix(0.704335 -0.709867 0.704335 0.709867 0 -0.41394)"
          fill={bgColor}
        />
      </g>
    </svg>
  );
};
