import styled from 'styled-components';
import { COLORS, FOUNDATION_COLOR } from 'styles/constants/_colors';

export const NoResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 190px);
  gap: 12px;
  white-space: pre-line;
  text-align: center;

  &.normal {
    height: 100%;
  }
  p {
    font-size: 14px;
    font-weight: 500;
    color: ${COLORS.PRIMITIVES_GRAY_500};
    letter-spacing: -0.5px;
  }
  &.ban,
  &.public {
    border-top: 1px solid ${COLORS.PRIMITIVES_GRAY_BLUE_100};
    background: ${COLORS.PRIMITIVES_GRAY_60};
    color: ${FOUNDATION_COLOR.PRIMARY_GRAY};
    height: 65vh;
    font-weight: 500;
  }
  &.comingSoon {
    height: calc(100% - 50px);
    p {
      font-weight: 600;
      font-size: 30px;
      color: ${COLORS.PRIMITIVES_GRAY_450};
    }
  }
`;
