import usePostMessageReceiver from '@hooks/usePostMessageReceiver';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import useTopModal from '@hooks/useTopModal';
import PhotoRequirementModal from '@components/modal/PhotoRequirementModal';
import { useRecoilState } from 'recoil';
import { photoPermissionState } from '@states/BattleState';

const usePhotoPermissionCheck = () => {
  const [isPhotoDisabled, setPhotoState] = useRecoilState(photoPermissionState);
  const { openModal } = useTopModal();

  const checkPhotoPermission = () => {
    sendReactNativeMessage({
      controller: 'permission',
      service: 'photoPermissionCheck',
    });
  };

  const requestPhotoPermission = () => {
    sendReactNativeMessage({
      controller: 'permission',
      service: 'photoPermissionRequest',
    });
  };

  const openPhotoPermissionModal = () => {
    openModal(PhotoRequirementModal, {});
  };

  usePostMessageReceiver((event: any) => {
    const { type, data } = JSON.parse(event.data);

    if (type === 'photoRequestResult' || type === 'photoCheckResult') {
      const permissionDisabled = !Object.values(data).every(
        (v) => v === 'granted' || v === 'limited',
      );

      setPhotoState(permissionDisabled);
    }
  });

  return {
    isPhotoDisabled,
    checkPhotoPermission,
    requestPhotoPermission,
    openPhotoPermissionModal,
  };
};

export default usePhotoPermissionCheck;
