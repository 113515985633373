import Skeleton from '@components/Skeleton';
import { COLORS } from '@styles/constants/_colors';
import styled from 'styled-components';

interface Props {
  padding?: string;
}

const MyRoomItemListSkeleton = ({ padding }: Props) => {
  return (
    <Wrapper padding={padding ? padding : '57px 20px 0px'}>
      {Array.from({ length: 12 }, (_, i) => (
        <Card key={i}>
          <Box>
            <Skeleton
              width="100%"
              height="100%"
              radius={12}
              background={COLORS.PRIMITIVES_GRAY_300}
            />
          </Box>
          <Skeleton width={58} height={16} radius={120} background={COLORS.PRIMITIVES_GRAY_300} />
        </Card>
      ))}
    </Wrapper>
  );
};

export default MyRoomItemListSkeleton;

const Wrapper = styled.div<{ padding: string }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 9.5px;
  row-gap: 30px;
  padding: ${({ padding }) => padding};
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
  width: 100%;
`;

const Box = styled.div`
  border-radius: 12px;
  width: 100%;
  aspect-ratio: 1 / 1;
`;
