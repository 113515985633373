import {
  RoomSidebarActiveIcon,
  RoomSidebarIcon,
  ColorfulCartIcon,
  ColorfulTrashIcon,
  LayerDownIcon,
  LayerUpIcon,
} from '@assets/svgs/_Icons';
import BottomButton from '@components/BottomButton';
import { COLORS } from '@styles/constants/_colors';
import styled from 'styled-components';
import cx from 'classnames';
import { GroupBattlePlusIcon } from '@assets/svgs/illust';

interface Props {
  onCleanRoom: () => void;
  onClickItemAdd: () => void;
  onClickCart: () => void;
  onLayerUp: () => void;
  onLayerDown: () => void;
  onToggleSidebar: () => void;
  showSidebar: boolean;
  showLayerControlMenu: boolean;
  itemLength: number;
  purchaseRequiredCount: number;
  sidebarComponent: false | React.ReactNode;
}
const RoomEditorBottomMenu = ({
  onCleanRoom,
  onClickItemAdd,
  onClickCart,
  onLayerUp,
  onLayerDown,
  onToggleSidebar,
  showSidebar,
  showLayerControlMenu,
  itemLength,
  purchaseRequiredCount,
  sidebarComponent,
}: Props) => {
  return (
    <>
      <Wrapper className="editor-menu">
        <IconButtonBox>
          <IconButton onClick={onCleanRoom}>
            <ColorfulTrashIcon />
            <ButtonText>방 비우기</ButtonText>
          </IconButton>
          <IconButton onClick={onClickCart}>
            <ColorfulCartIcon />
            <PurchaseCount count={String(purchaseRequiredCount)}>
              <p>{purchaseRequiredCount}</p>
            </PurchaseCount>
            <ButtonText>구매 필요</ButtonText>
          </IconButton>
        </IconButtonBox>
        <BottomButton height={56} color="black" onClickEvent={onClickItemAdd}>
          <GroupBattlePlusIcon width={20} height={20} />
          <p>아이템 추가</p>
        </BottomButton>
      </Wrapper>

      {/* 룸 아이템 z-index 컨트롤 메뉴 */}
      <LayerControlMenuField
        upY={0}
        downY={106}
        className={cx('editor-menu', [showLayerControlMenu ? 'up' : 'down'])}
      >
        <LayerControlButton>
          <LayerUpIcon onClick={onLayerUp} className="layer-icon" />
          <LayerText>한 칸 위로</LayerText>
        </LayerControlButton>
        <LayerControlButton>
          <LayerDownIcon onClick={onLayerDown} className="layer-icon" />
          <LayerText>한 칸 아래로</LayerText>
        </LayerControlButton>
        <LayerControlButton>
          {showSidebar ? (
            <RoomSidebarActiveIcon onClick={onToggleSidebar} />
          ) : (
            <RoomSidebarIcon onClick={onToggleSidebar} />
          )}
          {showSidebar && showLayerControlMenu && <SidebarBox>{sidebarComponent}</SidebarBox>}
          <LayerCount>
            <p>{itemLength}</p>
          </LayerCount>
          <LayerText>모든 순서</LayerText>
        </LayerControlButton>
      </LayerControlMenuField>
    </>
  );
};

export default RoomEditorBottomMenu;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: 100%;
  max-width: 428px;
  height: 80px;
  padding: 0px 12px 0px 24px;
  background-color: ${COLORS.WHITE};
  position: fixed;
  bottom: 0;
  z-index: 500;

  > button:last-of-type > p {
    margin-left: 2px;
  }
`;

const IconButtonBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 21px;
  transform: translateY(-3px);
`;

const IconButton = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
  min-width: 49px;
`;

const ButtonText = styled.p`
  color: ${COLORS.BLACK};
  text-align: center;
  font-family: 'Noto Sans KR';
  font-size: 13px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.5px;
`;

const PurchaseCount = styled.div<{ count: string }>`
  position: absolute;
  top: 0px;
  right: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 21px;
  height: 21px;
  padding: ${({ count }) => (count.length === 1 ? '0px' : '0px 6px')};
  background-color: ${({ count }) => (count === '0' ? COLORS.PRIMITIVES_GRAY_300 : COLORS.RED)};
  border-radius: ${({ count }) => (count.length === 1 ? '50%' : '120px')};

  p {
    transform: translateY(-0.5px);
    color: ${({ count }) => (count === '0' ? COLORS.PRIMITIVES_GRAY_500 : COLORS.WHITE)};
    text-align: center;
    font-family: Campton;
    font-size: 12px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.5px;
  }
`;

const LayerControlMenuField = styled.div<{ upY: number; downY: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  column-gap: 39px;
  width: 100%;
  max-width: 428px;
  height: 106px;
  background-color: ${COLORS.WHITE};
  bottom: 0px;
  z-index: 1000;

  &.up {
    transform: translateY(${({ upY }) => `${upY}px`});
    transition-duration: 300ms;
  }
  &.down {
    transform: translateY(${({ downY }) => `${downY}px`});
    transition-duration: 300ms;
  }
`;

const LayerControlButton = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 62px;
  row-gap: 4px;

  .layer-icon {
    &:active {
      opacity: 0.4;
    }
  }
`;

const LayerText = styled.p`
  color: ${COLORS.BLACK};
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.5px;
`;

const LayerCount = styled.div`
  position: absolute;
  top: 6px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  background-color: ${COLORS.BLACK};
  border-radius: 50%;

  p {
    transform: translateY(-0.5px);
    color: ${COLORS.WHITE};
    text-align: center;
    font-family: Campton;
    font-size: 12px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.5px;
  }
`;

const SidebarBox = styled.div`
  position: absolute;
  z-index: 5000;
  bottom: 86px;
  right: -5px;
`;
