import { MyRoomItemsType, UniqueCartRoomItem } from '@models/myRoom';
import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';

interface Props {
  items?: UniqueCartRoomItem[];
}

function useItemSelect({ items }: Props) {
  const [selected, setSelected] = useState<string[]>([]);

  const onCheckboxAllClick = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!items) return;

      if (event.target.checked) {
        const newSelectedRowIds = items.map((row) => row.id);
        const mergedRow = [...selected, ...newSelectedRowIds].filter((id, index, self) => {
          return self.indexOf(id) === index;
        });
        setSelected([...mergedRow]);
      } else {
        const filterRowIds = selected.filter((id) => !items.map((row) => row.id).includes(id));
        setSelected(filterRowIds);
      }
    },
    [items, selected],
  );

  const onCheckboxItemClick = useCallback(
    (_: MouseEvent<unknown, MouseEvent>, row: Omit<MyRoomItemsType, 'x' | 'y'>) => {
      const newSelected = selected.includes(row.id)
        ? selected.filter((item) => item !== row.id)
        : [...selected, row.id];
      setSelected(newSelected);
    },
    [selected],
  );

  const isHeaderChecked = useMemo(() => {
    if (!items) return false;

    return items.every((row) => selected.includes(row.id));
  }, [items, selected]);

  const onClearSelected = () => {
    setSelected([]);
  };

  useEffect(() => {
    setSelected(items?.map((item) => item.id) ?? []);
  }, []);

  return {
    isHeaderChecked,
    selected,
    onCheckboxAllClick,
    onCheckboxItemClick,
    onClearSelected,
  };
}

export default useItemSelect;
