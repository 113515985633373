import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import Portal from '@components/portal';
import { COLORS } from '@styles/constants/_colors';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import roomSaveImg from '@assets/images/modal/roomSave.png';

interface Props {
  onClose: () => void;
  onSubmit: () => void;
}

const SaveModal = ({ onClose, onSubmit }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsOpen(true), 100);
  }, []);

  return (
    <Portal>
      <DimmedWrapper onClose={onClose}>
        <ModalField isOpen={isOpen}>
          <ContentField>
            <TitleUnit>
              정말 멋진 방이네요!
              <br />
              이대로 저장할까요?
            </TitleUnit>
            <ImageUnit src={roomSaveImg} />
            <ActionBox>
              <LeftButtonUnit onClick={onClose}>아니오</LeftButtonUnit>
              <ButtonUnit onClick={onSubmit}>네 좋아요!</ButtonUnit>
            </ActionBox>
          </ContentField>
        </ModalField>
      </DimmedWrapper>
    </Portal>
  );
};

export default SaveModal;

const ModalField = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 50%;
  top: ${({ isOpen }) => (isOpen ? '50%' : '100%')};
  transform: ${({ isOpen }) => (isOpen ? 'translate(-50%, -50%)' : 'translate(-50%, 100%)')};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 428px;
  padding: 0 40px;
  overflow: hidden;
  transition: all 200ms ease;
`;

const ContentField = styled.div`
  background-color: ${COLORS.WHITE};
  padding: 38px 30px 30px;
  border-radius: 26px;
`;

const TitleUnit = styled.h1`
  color: ${COLORS.BLACK};
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 145%;
  letter-spacing: -0.5px;
  margin: 0;
  font-family: 'Cafe24 Ssurround';
`;

const ImageUnit = styled.img`
  width: 100%;
  object-fit: contain;
  margin-top: 8px;
`;

const ActionBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  margin-top: 20px;
`;

const LeftButtonUnit = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 76px;
  height: 50px;
  border-radius: 120px;

  font-family: 'Cafe24 Ssurround';
  font-size: 14px;
  font-weight: 700;
  color: ${COLORS.PRIMITIVES_GRAY_550};
  background-color: ${COLORS.PRIMITIVES_GRAY_200};
`;

const ButtonUnit = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Cafe24 Ssurround';
  font-size: 14px;
  font-weight: 700;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.BLACK};
`;
