/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { MyIcon } from '@assets/svgs/_Icons';
import LikeIcon from '@assets/svgs/_Icons/LikeIcon';
import useMyRoomMainCategoryQuery from '@pages/my/hooks/useMyRoomMainCategoryQuery';
import {
  myRoomCurrentMainCategoryState,
  myRoomCurrentSubCategoryState,
  myRoomItemListBottomSheetScrollState,
} from '@states/myRoomState';
import { COLORS } from '@styles/constants/_colors';

const CategoryList = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const [scroll, setScroll] = useRecoilState(myRoomItemListBottomSheetScrollState);
  const { mainCategoryList } = useMyRoomMainCategoryQuery();
  const [currentMainCategory, setCurrentMainCategory] = useRecoilState(
    myRoomCurrentMainCategoryState,
  );
  const setCurrentSubCategory = useSetRecoilState(myRoomCurrentSubCategoryState);

  const handleClickMainCategory = (tab: string) => {
    queryClient.removeQueries({ queryKey: ['myRoom', 'itemList'], exact: false });
    setCurrentMainCategory(tab);
    setCurrentSubCategory('전체');
    setScroll((prev) => ({
      ...prev,
      subCategoryScrollX: 0,
      scrollY: {
        purchased: 0,
        liked: 0,
        categories: 0,
      },
    }));
  };

  useLayoutEffect(() => {
    if (!wrapperRef.current) return;
    wrapperRef.current.scrollTo(scroll.mainCategoryScrollX, 0);

    return () => {
      if (!wrapperRef.current) return;
      setScroll((prev) => ({
        ...prev,
        mainCategoryScrollX: wrapperRef.current?.scrollLeft ?? 0,
      }));
    };
  }, [setScroll]);

  return (
    <Wrapper ref={wrapperRef}>
      <TabField>
        <Tab onClick={() => handleClickMainCategory('마이')}>
          <IconBox isSelected={currentMainCategory === '마이'}>
            <MyIcon />
          </IconBox>
          <MainCategoryTitle fontWeight={900} isSelected={currentMainCategory === '마이'}>
            My
          </MainCategoryTitle>
        </Tab>
        <Tab onClick={() => handleClickMainCategory('좋아요')}>
          <IconBox isSelected={currentMainCategory === '좋아요'}>
            <LikeIcon active size="l" width={26} height={26} />
          </IconBox>
          <MainCategoryTitle isSelected={currentMainCategory === '좋아요'}>
            좋아요
          </MainCategoryTitle>
        </Tab>
        {mainCategoryList.map((category) => (
          <Tab key={category.value} onClick={() => handleClickMainCategory(category.value)}>
            <IconBox isSelected={currentMainCategory === category.value}>
              <img src={category.icon} width={32} height={32} />
            </IconBox>
            <MainCategoryTitle isSelected={currentMainCategory === category.value}>
              {category.value}
            </MainCategoryTitle>
          </Tab>
        ))}
      </TabField>
    </Wrapper>
  );
};

export default CategoryList;

const Wrapper = styled.div`
  margin-top: 14px;
  width: 100%;
  overflow-x: auto;
  background-color: ${COLORS.WHITE};
  height: 92px;
`;

const TabField = styled.div`
  width: min-content;
  display: flex;
  align-items: center;
  column-gap: 12px;
  padding: 0px 20px 16px;
`;

const Tab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
  row-gap: 9px;
`;

const IconBox = styled.div<{ isSelected: boolean }>`
  border-radius: 16px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isSelected }) => (isSelected ? COLORS.PRIMITIVES_GRAY_60 : 'transparent')};
`;

const MainCategoryTitle = styled.p<{ isSelected: boolean; fontWeight?: number }>`
  color: ${({ isSelected }) => (isSelected ? COLORS.BLACK : COLORS.PRIMITIVES_GRAY_450)};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || 700};
  line-height: 120%;
  letter-spacing: -0.5px;
`;
