import { createStyles, Grid, makeStyles } from '@material-ui/core';
import ToastPageMsg from 'components/elements/ToastPageMsg';
import { ThemeButton } from 'pages/exercise/ExerciseDiary/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { waterTotalState } from 'states/MainInfoState';
import { toastMsgState } from 'states/SearchResultState';
import { getWaterInfo } from '../../../apis/mainApi';
import SpinnerInBox from '../../../components/SpinnerInBox';
import { useDate } from '../../../hooks/useDate';
import WaterCup from '../elements/WaterCup';
import { useCoordinate } from '@hooks/useCoordinate';

export default function WaterDiary() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setCoordinate } = useCoordinate();

  const [loading, setLoading] = useState(true);
  const [toastMsg, setToastMsg] = useRecoilState(toastMsgState);
  const { date } = useDate();

  const [waterTotal, setWaterTotal] = useRecoilState<number>(waterTotalState);

  const moveToDetail = () => {
    setCoordinate();
    navigate('/tags/water');
  };

  const ReplaceToLiter = (mlTotal: number) => {
    return (mlTotal * 0.001).toFixed(2);
  };

  const getWaterInfoFun = async () => {
    if (date) {
      const data = await getWaterInfo(date);
      setWaterTotal(data.waterTotal);
    }
  };

  useEffect(() => {
    setLoading(true);
    getWaterInfoFun();
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [date, waterTotal]);

  return (
    <>
      {loading ? (
        <SpinnerInBox height={'482.6px'} />
      ) : (
        <>
          <Grid className={classes.container} container direction={'column'} alignItems={'center'}>
            <p className={classes.title}>
              오늘 물 섭취량은<span>?</span>
            </p>
            <p className={classes.intake}>
              {ReplaceToLiter(waterTotal)}
              <span>L</span>
            </p>

            <Grid container justifyContent={'center'} alignItems={'center'} className={classes.cup}>
              <WaterCup waterTotal={waterTotal} />
            </Grid>

            <ThemeButton color={'#22487D'}>
              <a onClick={moveToDetail}>{waterTotal ? '수정하기' : '기록하기'}</a>
            </ThemeButton>
          </Grid>
          <ToastPageMsg onToast={toastMsg} setOnToast={setToastMsg}>
            기록이 완료되었어요
          </ToastPageMsg>
        </>
      )}
    </>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: '77px 20px 65.5px',
    },
    title: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 1,
      letterSpacing: -0.5,
      color: '#fff',
      '& span': {
        fontSize: 18,
        fontWeight: 600,
      },
    },
    intake: {
      margin: '16px 0 28px',
      fontSize: 50,
      fontWeight: 600,
      lineHeight: 1,
      letterSpacing: -0.5,
      color: '#fff',
      '& span': {
        paddingLeft: 6,
        fontSize: 30,
        lineHeight: 1.5,
      },
    },
    cup: {
      marginBottom: 40,
    },
  }),
);
