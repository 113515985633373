import { useInfiniteQuery } from 'react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { myRoomLikeCountListState, myRoomLikeListState } from '@states/StoreState';
import { getStoreMyRoomList } from '@apis/storeApi';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import { useEffect, useMemo } from 'react';
import { myRoomCurrentMainCategoryState, myRoomCurrentSubCategoryState } from '@states/myRoomState';

const useMyRoomItemListQuery = () => {
  const mainCategory = useRecoilValue(myRoomCurrentMainCategoryState);
  const subCategory = useRecoilValue(myRoomCurrentSubCategoryState);
  const setLikeList = useSetRecoilState(myRoomLikeListState);
  const setLikeCountList = useSetRecoilState(myRoomLikeCountListState);

  const { memberIdx } = useUserInfoQuery();

  const query = useInfiniteQuery(
    ['myRoom', 'itemList', 'categories', mainCategory, subCategory, memberIdx],
    ({ pageParam = 0 }) => getStoreMyRoomList(memberIdx, mainCategory, subCategory, '', pageParam),
    {
      enabled: !!mainCategory && !!subCategory,
      getNextPageParam: (lastPage, allPage) => allPage.length * 10,
    },
  );

  const myRoomList = useMemo(() => query.data?.pages.flatMap((page) => page) ?? [], [query.data]);

  useEffect(() => {
    if (myRoomList.length !== 0) {
      const likeList = myRoomList
        ?.filter(({ liked }) => liked === 1)
        .map(({ room_item_idx }) => room_item_idx);

      const likeCountList = new Map();

      for (const { room_item_idx, likes } of myRoomList) {
        likeCountList.set(room_item_idx, likes);
      }

      setLikeList(new Set(likeList));
      setLikeCountList(likeCountList);
    }
  }, [myRoomList]);

  return { ...query, myRoomList };
};

export default useMyRoomItemListQuery;
