import { useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import BottomButton from '@components/BottomButton';
import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import BottomSheet from '@components/modal/bottomSheet/BottomSheet';
import Portal from '@components/portal';
import { COLORS } from '@styles/constants/_colors';
import useToastModal from '@hooks/useToastModal';

interface Props {
  onClose: () => void;
  id: string;
  initialText?: string;
  onConfirm: (id: string, text: string) => void;
}

const WriteBottomSheet = ({ onClose, id, onConfirm, initialText }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { openModal: openToast } = useToastModal();
  const [text, setText] = useState(initialText ?? '');

  const handleRegExp = (text: string) => {
    const pattern = /^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9 _\-\s!@#$%^&*()+=~`|\\[\]{}:;"'<>,.?/]+$/;
    return pattern.test(text);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isBackspaceKey = e.key === 'Backspace';
    if (isBackspaceKey && text.length > 20) {
      setText(text.slice(0, -1));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isRegExp = handleRegExp(e.target.value);
    const isSpace = e.target.value === '';
    if (e.target.value.length > 20 || (!isRegExp && !isSpace)) return;
    setText(e.target.value);
  };

  const handleConfirm = () => {
    onClose();
    onConfirm(id, text);
    openToast({ children: <p>상태 메시지 입력을 완료했어요</p> });
  };

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  useLayoutEffect(() => {
    if (inputRef.current) {
      const input = inputRef.current;
      input.focus();
    }
  }, []);

  return (
    <Portal>
      <DimmedWrapper onClose={onClose} className="editor-menu">
        <BottomSheet containerPadding="12px 0px 0px 0px" onClose={onClose}>
          <BottomSheet.Contents>
            <ContentField>
              <Input
                ref={inputRef}
                value={text}
                placeholder="나의 상태메시지"
                onChange={handleChange}
                onKeyDown={onKeyDown}
                type="text"
                inputMode="text"
              />
              <Text $color={text.length > 0 ? COLORS.BLACK : COLORS.PRIMITIVES_GRAY_400}>
                <span>{text.length}</span> / 20
              </Text>
            </ContentField>
          </BottomSheet.Contents>
          <BottomSheet.Buttons>
            <ButtonBox>
              <BottomButton color="black" isDisabled={!text} onClickEvent={handleConfirm}>
                완료
              </BottomButton>
            </ButtonBox>
          </BottomSheet.Buttons>
        </BottomSheet>
      </DimmedWrapper>
    </Portal>
  );
};

export default WriteBottomSheet;

const ContentField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 38px 33.5px 0px;
`;

const Input = styled.input`
  width: 100%;
  height: 29px;
  margin-bottom: 16px;
  color: ${COLORS.BLACK};
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.5px;

  &::placeholder {
    color: ${COLORS.PRIMITIVES_GRAY_400};
    font-size: 24px;
  }
`;

const Text = styled.p<{ $color: string }>`
  color: ${COLORS.PRIMITIVES_GRAY_400};
  text-align: center;
  font-family: Campton;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.538px;
  margin-bottom: 24px;

  span {
    color: ${({ $color }) => $color};
  }
`;

const ButtonBox = styled.div`
  padding: 12px 20px;
`;
