import SpinnerInBox from '@components/SpinnerInBox';
import { useWeightChange } from '@hooks/useWeightChange';

import { ThemeButton } from 'pages/exercise/ExerciseDiary/styles';
import WeightInputModal from 'pages/main/components/WeightInputModal';
import { useEffect, useMemo, useState } from 'react';
import { COLORS } from '@styles/constants/_colors';
import styled from 'styled-components';
import { OneSideArrow14 } from '@assets/svgs/_Icons';
import MyBMIText from './components/MyBMIText';
import MyBMIModal from './components/MyBMIModal';
import useTopModal from '@hooks/useTopModal';
import useBMICalculation from '@pages/main/hooks/useBMICalculation';
import RefreshArrow from '@assets/svgs/_Icons/arrow/RefreshArrow';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import { dateState } from '@states/DateState';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import useHealthExceptionCase from '@hooks/health/useHealthExceptionCase';
import { deviceOs } from '@modules/platformCheck';
import { deepLinkEventDataState } from '@states/DeepLinkState';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import WeightResponsePopup from '@pages/main/components/WeightResponsePopup';

export default function WeightDiary() {
  const date = useRecoilValue(dateState);
  const { data: userInfo } = useUserInfoQuery();
  const height = useMemo(() => userInfo?.height ?? 0, [userInfo?.height]);

  const { handleBMIalCalculation } = useBMICalculation();
  const { isLoading, onSubmitWeight, weightState, onSubmitDelete, ref } = useWeightChange();
  const todayDataEmptyStatus = weightState.weight === 0 || !weightState.weight;

  const { closeModal, openModal } = useTopModal();
  const {
    onUnSyncHealthDataPopup,
    redirectHealthConnectPlayStore,
    checkHealthConnectModalView,
    onAndroidHealthNotionPage,
  } = useHealthExceptionCase();
  const [isNativeEventLoading, setIsNativeEventLoading] = useState(false);
  const deepLinkEventData = useRecoilValue(deepLinkEventDataState);
  const resetDeepLinkEventData = useResetRecoilState(deepLinkEventDataState);

  const { bmi } = handleBMIalCalculation(weightState.weight, height);

  const openBMIModal = () => {
    openModal(MyBMIModal, {
      closeModal: () => {
        closeModal(MyBMIModal);
      },
      bmi,
    });
  };

  const openResultModal = async (weightData: number) => {
    const { status, weightChange } = await onSubmitWeight(Number(weightData));

    openModal(WeightResponsePopup, {
      closePopup: () => {
        closeModal(WeightResponsePopup);
      },
      weight: Number(weightData),
      weightChange: weightChange,
      status: status,
    });
  };

  const { closeModal: onSubmitWeightModalClose, openModal: onSubmitWeightModalOpen } =
    useTopModal();

  const openSubmitWeightModal = () => {
    onSubmitWeightModalOpen(WeightInputModal, {
      closeModal: () => {
        onSubmitWeightModalClose(WeightInputModal);
      },
      type: todayDataEmptyStatus ? undefined : 'weight',
      weightState,
      onSubmitWeight,
      onSubmitDelete,
    });
  };

  const weightChangeValue = useMemo(() => {
    if (weightState.weightChange === 0) return `-0`;
    if (weightState.weightChange > 0) return `+${weightState.weightChange}`;
    return weightState.weightChange;
  }, [weightState.weightChange]);

  /**
   * [건강 데이터 연동 로직]
   * 이벤트 리스너를 날짜가 바뀔 때마다 새로 등록해주어야 하기 때문에
   * 의존성 배열로 날짜를 넣어준 Receiver 사용(공통적으로 쓰는 usePostMessageReceiver 미사용 이유)
   */

  const onSyncWight = () => {
    if (deviceOs === 'android') {
      const isCheckedModal = checkHealthConnectModalView();
      if (isCheckedModal !== 'checked') {
        onAndroidHealthNotionPage();
        return;
      }
    }

    sendReactNativeMessage({
      type: 'health',
      payload: { date },
      service: 'getWeight',
    });
    setIsNativeEventLoading(true);
  };

  const convertGramToKg = (data: number) => {
    return Number((data * 0.001).toFixed(1));
  };

  const postMessageListener = (event: any) => {
    const { type, data } = JSON.parse(event.data);

    if (type === 'healthConnectUnInstall') {
      redirectHealthConnectPlayStore();
      return;
    }

    if (type === 'getWeight' && data !== '') {
      const weightData = convertGramToKg(Number(data));
      openResultModal(weightData);
      setIsNativeEventLoading(false);
      return;
    }

    if (type === 'getWeight' || type == 'iosHealthSyncFail') {
      onUnSyncHealthDataPopup();
      setIsNativeEventLoading(false);
      return;
    }
  };

  useEffect(() => {
    if (deviceOs === 'ios') {
      window.addEventListener('message', postMessageListener);
      return () => {
        window.removeEventListener('message', postMessageListener);
      };
    }

    document.addEventListener('message', postMessageListener);
    return () => {
      document.removeEventListener('message', postMessageListener);
    };
  }, [date]);

  useEffect(() => {
    if (deepLinkEventData && deepLinkEventData?.section === 'weight') {
      onSubmitWeightModalOpen(WeightInputModal, {
        closeModal: () => {
          onSubmitWeightModalClose(WeightInputModal);
          resetDeepLinkEventData();
        },
        type: deepLinkEventData?.weight === '-1.0' ? undefined : 'weight',
        weightState: {
          ...weightState,
          weight: Number(deepLinkEventData?.weight === '-1.0' ? 0 : deepLinkEventData?.weight),
        },
        onSubmitWeight,
        onSubmitDelete,
      });
    }
  }, [deepLinkEventData]);

  return (
    <div ref={ref}>
      {isLoading || isNativeEventLoading ? (
        <SpinnerInBox height={'492px'} />
      ) : (
        <Wrapper>
          <Title>
            {todayDataEmptyStatus ? (
              <>
                오늘 내 체중은<span>?</span>
              </>
            ) : (
              <TitleBox>
                이전보다 {weightChangeValue}
                <Title>kg</Title>
              </TitleBox>
            )}
          </Title>
          <Weight>
            {todayDataEmptyStatus ? '00.0' : weightState.weight}
            <span>kg</span>
          </Weight>
          <WeightImg
            src="https://dw543otwhx7wx.cloudfront.net/main/main3_weight.svg"
            alt="체중계 이미지"
            isDataEmpty={todayDataEmptyStatus}
          />
          {!todayDataEmptyStatus && (
            <MyBMIButton onClick={openBMIModal}>
              <MyBMIText />
              <span>:</span>
              {bmi}
              <OneSideArrow14 strokeWidth="1.7" color="white" />
            </MyBMIButton>
          )}
          <ThemeButton color={COLORS.PRIMITIVES_APRICOT_500}>
            <a onClick={openSubmitWeightModal}>{todayDataEmptyStatus ? '기록하기' : '수정하기'}</a>
          </ThemeButton>

          <SyncHealthAppBox onClick={onSyncWight}>
            <p>건강 앱에서 불러오기</p>
            <RefreshArrow color={COLORS.PRIMITIVES_APRICOT_600} />
          </SyncHealthAppBox>
        </Wrapper>
      )}
    </div>
  );
}

const Wrapper = styled.div`
  padding: 56px 20px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TitleBox = styled.div`
  display: flex;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.5;
  color: #fff;

  span {
    font-size: 18px;
  }

  &:last-child {
    margin-left: 2px;
  }
`;

const Weight = styled.p`
  font-size: 50px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.5;
  color: #fff;
  padding: 16px 0 24px;

  span {
    padding-left: 6px;
    font-size: 30px;
  }
`;

const WeightImg = styled.img<{ isDataEmpty: boolean }>`
  margin-bottom: ${({ isDataEmpty }) => (isDataEmpty ? '24px' : '12px')};
`;

const MyBMIButton = styled.button`
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  letter-spacing: -0.62px;
  color: #fff;
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
  margin: 0px 4px;
  margin-bottom: 28px;
  transform: translateY(-1px);

  & > span {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    letter-spacing: -0.62px;
    color: #fff;
  }

  svg:last-child {
    transform: translateY(1px);
  }
`;

const SyncHealthAppBox = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 26px;

  color: ${COLORS.PRIMITIVES_APRICOT_600};
  font-family: Noto Sans KR;
  font-size: 13px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.615px;
`;
