import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import BottomSheet from '@components/modal/bottomSheet/BottomSheet';
import Portal from '@components/portal';
import CategoryList from './CategoryList';
import ItemList from './ItemList';
import { useLayoutEffect } from 'react';

interface Props {
  onClose: () => void;
  ratio: number;
}

const RoomItemListBottomSheet = ({ onClose, ratio }: Props) => {
  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  return (
    <Portal>
      <DimmedWrapper onClose={onClose}>
        <BottomSheet containerPadding="12px 0px 0px 0px" onClose={onClose}>
          <BottomSheet.Headers>
            <CategoryList />
          </BottomSheet.Headers>
          <BottomSheet.Contents>
            <ItemList onClose={onClose} ratio={ratio} />
          </BottomSheet.Contents>
        </BottomSheet>
      </DimmedWrapper>
    </Portal>
  );
};

export default RoomItemListBottomSheet;
