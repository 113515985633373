import { CircleSelectGrayIcon, CircleSelectIcon } from '@assets/svgs';
import styled from 'styled-components';

interface Props {
  onClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
  id: string;
}

const CircleCheck = ({ isChecked, onClick, id }: Props) => {
  const check = (isChecked: boolean) => {
    return isChecked ? <CircleSelectIcon /> : <CircleSelectGrayIcon />;
  };

  return (
    <Box>
      <StyledLabel htmlFor={id}>{check(!!isChecked)}</StyledLabel>
      <InputRadio type="checkbox" id={id} checked={isChecked} onChange={onClick} />
    </Box>
  );
};

export default CircleCheck;

const Box = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLabel = styled.label`
  cursor: pointer;
  height: 28px;
`;

const InputRadio = styled.input`
  display: none;
`;
