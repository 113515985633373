import { forwardRef } from 'react';
import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';

interface Props {
  src: string;
  ratio: number;
  imgWidth: number;
  imgHeight: number;
  text: string;
}

const SpeechBubble = forwardRef<HTMLDivElement, Props>(
  ({ src, ratio, imgWidth, imgHeight, text }, ref) => {
    return (
      <SpeechBubbleBox ref={ref}>
        <SpeechBubbleItem ratio={ratio}>
          <SpeechBubbleText>{text}</SpeechBubbleText>
        </SpeechBubbleItem>
        <Img src={src} width={imgWidth} height={imgHeight} data-type="img" />
      </SpeechBubbleBox>
    );
  },
);

export default SpeechBubble;

const SpeechBubbleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SpeechBubbleItem = styled.div<{ ratio: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: ${COLORS.WHITE};
  width: ${({ ratio }) => Math.round(189 * ratio)}px;
  min-height: ${({ ratio }) => Math.round(49 * ratio)}px;
  padding: 12px 17.5px;
`;

const SpeechBubbleText = styled.p`
  color: ${COLORS.PRIMITIVES_GRAY_600};
  text-align: center;
  font-family: LeeSeoyun;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.447px;
  transform: translateY(-1px);
`;

const Img = styled.img`
  object-fit: contain;
  transform: translateY(-0.2px);
`;
