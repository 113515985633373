import { getToday } from '@utils/fotmatDate';
import {
  CommunityActiveIcon,
  CommunityIcon,
  FeedActiveIcon,
  FeedIcon,
  MyRoomActiveIcon,
  MyRoomIcon,
  RecordActiveIcon,
  RecordIcon,
} from 'assets/svgs/tabBar';
import { exerciseThemeIndex } from 'pages/exercise/states';
import { myChangeTabIndexState, myEatTabIndexState } from 'pages/main/states';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { communityTypeState } from 'states/CommunityState';
import styled, { css } from 'styled-components';
import { ApplePointCenterIcon, ApplePointCenterOffIcon } from '@assets/svgs/illust';
import { COLORS } from '@styles/constants/_colors';
import useNavigationNewsQuery from '@hooks/queries/useNavigationNewsQuery';
import { anonymityKeys } from '@constants/query';

interface Props {
  index: number;
  isSelected: boolean;
}

export default function Menu({ index, isSelected }: Props) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: newsState } = useNavigationNewsQuery();

  const [, setCommunityType] = useRecoilState(communityTypeState);
  const [, setMyChangeTabIndex] = useRecoilState(myChangeTabIndexState);
  const [, setMyEatTabIndex] = useRecoilState(myEatTabIndexState);
  const [, SetExerciseThemeIndex] = useRecoilState(exerciseThemeIndex);

  const menuList = [
    { title: '기록', icon: <RecordIcon />, selectedIcon: <RecordActiveIcon /> },
    {
      title: '마이룸',
      icon: <MyRoomIcon />,
      selectedIcon: <MyRoomActiveIcon />,
    },
    {
      title: '사과농장',
      icon: <ApplePointCenterOffIcon style={{ marginLeft: '1px' }} />,
      selectedIcon: <ApplePointCenterIcon style={{ marginLeft: '1px' }} />,
      accent: true,
    },
    { title: '피드', icon: <FeedIcon />, selectedIcon: <FeedActiveIcon /> },
    { title: '대나무숲', icon: <CommunityIcon />, selectedIcon: <CommunityActiveIcon /> },
  ];
  const currentMenu = menuList[index];

  const hasNews = index === 1 && newsState?.myProfile;

  const resetMainIndex = () => {
    setMyChangeTabIndex(0);
    setMyEatTabIndex(0);
    SetExerciseThemeIndex(0);
  };

  const removeScrollRestorationItems = () => {
    Object.keys(sessionStorage).forEach((key) => {
      if (key.startsWith('useScrollRestoration')) {
        sessionStorage.removeItem(key);
      }
    });
  };

  const moveToPage = (index: number) => {
    removeScrollRestorationItems();
    if (isSelected) {
      window.scrollTo(0, 0);
      return;
    }

    switch (index) {
      case 0:
        navigate('/');
        setCommunityType('');
        break;
      case 1:
        navigate('/myPage/mainRoom');
        setCommunityType('');
        break;
      case 2:
        navigate('/apple/point?menu=일일미션');
        setCommunityType('');
        break;
      case 3:
        queryClient.resetQueries(['feed', 'V2']);
        queryClient.resetQueries(['feedRecommend']);
        navigate(
          `/communication/ketogram/recommend?tab=실시간&startDate=${getToday()}&endDate=${getToday()}`,
        );
        break;
      case 4:
        queryClient.resetQueries([anonymityKeys.RECOMMEND]);
        navigate('/communication/anonymity/recommend');
        break;
      default:
        navigate('/');
        setCommunityType('');
    }

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (isSelected && index !== 0) {
      resetMainIndex();
    }
  }, [isSelected, index]);

  return (
    <>
      <MenuItemBox
        $accent={!!currentMenu.accent}
        $selected={isSelected}
        onClick={() => moveToPage(index)}
      >
        <IconBox>
          {isSelected ? currentMenu.selectedIcon : currentMenu.icon}
          {hasNews && <NewsDot />}
        </IconBox>
        <span>{currentMenu.title}</span>
      </MenuItemBox>
    </>
  );
}

const MenuItemBox = styled.div<{ $selected: boolean; $accent: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 64px;
  margin-bottom: 2px;

  > span {
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    color: ${COLORS.PRIMITIVES_GRAY_500};

    ${({ $selected, $accent }) =>
      $selected &&
      css`
        color: ${$accent ? '#FF4800' : COLORS.BLACK};
        font-weight: 700;
      `}
  }
`;

const IconBox = styled.div`
  position: relative;
  height: 30px;
  margin-top: 2px;
  margin-bottom: 1px;

  > svg {
    height: 32px;
  }
`;

const NewsDot = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  right: 0;
  top: 0;
  border-radius: 50%;
  background-color: ${COLORS.RED};
`;
