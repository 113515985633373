import { makeStyles, Grid, createStyles, Theme } from '@material-ui/core';
import { PropsWithChildren } from 'react';

interface Props {
  width?: string | number;
  height?: string | number;
  maxWidth?: string | number;
  maxHeight?: string | number;
  padding?: string | number;
  margin?: string | number;
  round?: boolean;
  radius?: string | number;
  dark?: boolean;
  color?: boolean;
  background?: string;
  none?: boolean;
}

export default function Skeleton({
  children,
  width,
  height,
  padding = 0,
  margin = 0,
  round = false,
  maxWidth,
  maxHeight,
  radius,
  dark,
  color,
  none,
  background = '#F3F4F5',
}: PropsWithChildren<Props>) {
  const classes = useStyles({
    width,
    height,
    padding,
    margin,
    round,
    maxWidth,
    maxHeight,
    radius,
    dark,
    color,
    none,
    background,
  });

  const waveColorState = (() => {
    switch (background) {
      case '#F3F4F5':
        return {
          background: '#F3F4F5',
          gradient: '#F3F4F5',
        };
      case 'innerText':
        return {
          background: '#E6E7EA',
          gradient: '#f0f0f3',
        };
      case 'dark':
        return {
          background: '#192332',
          gradient: '#0c121ab2',
        };
    }
  })();

  return (
    <Grid className={classes.skeleton}>
      <Grid className={classes.wave} />
      {children}
    </Grid>
  );
}

const useStyles = makeStyles<Theme, Props>(() =>
  createStyles({
    skeleton: {
      position: 'relative',
      width: ({ width }) => width,
      height: ({ height }) => height,
      maxWidth: ({ maxWidth }) => (maxWidth ? maxWidth : 'auto'),
      maxHeight: ({ maxHeight }) => (maxHeight ? maxHeight : 'auto'),
      padding: ({ padding }) => padding,
      margin: ({ margin }) => margin,
      borderRadius: ({ round, radius }) => (round ? '50%' : radius ? radius : 3),
      background: ({ dark, background }) =>
        dark ? '#192332' : background ? background : '#F3F4F5',
      opacity: ({ dark, color, none }) => (none ? 0 : color ? 0.4 : 1),
      overflow: 'hidden',
      // zIndex: 10,
    },
    wave: {
      display: ({ none }) => (none ? 'none' : 'block'),
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100vh',
      width: '80%',
      // zIndex: 0,
      background: ({ dark, background }) =>
        dark
          ? 'linear-gradient(to right, #192332, #0c121ab2, #192332)'
          : `linear-gradient(to right, ${background}, #e6e6e9, ${background})`,
      animation: '$skeletonWave 1.5s infinite ease-in-out',
    },

    '@keyframes skeletonWave': {
      '0%': {
        transform: 'translateX(-100%)',
      },
      '100%': {
        transform: 'translateX(100%)',
      },
    },
  }),
);
