import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import Portal from '@components/portal';
import { COLORS } from '@styles/constants/_colors';
import LEVEL from '@styles/constants/_levels';
import { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import Shoes from '@assets/images/modal/shoes.png';

interface Props {
  walk: number;
  weight: number;
  closeModal: () => void;
}

const WalkingResultModal = ({ walk, weight, closeModal }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  useEffect(() => {
    setTimeout(() => setIsOpen(true), 100);
  }, []);

  return (
    <Portal>
      <DimmedWrapper>
        <ModalField isOpen={isOpen}>
          <ShoesImg src={Shoes} alt="shoes" />
          <Title>
            <span>{walk}</span>
            걸음 걸었어요!
          </Title>
          <Row>
            <Kcal>{`${Math.round(0.0006 * weight * walk)}kcal`}</Kcal>
            <KcalDesc>소모 예상</KcalDesc>
          </Row>
          <Desc>칼로리는 참고용이며 통계엔 반영되지 않아요</Desc>
          <Button onClick={closeModal}>확인</Button>
        </ModalField>
      </DimmedWrapper>
    </Portal>
  );
};

export default WalkingResultModal;

const ModalField = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 50%;
  top: ${({ isOpen }) => (isOpen ? '50%' : '100%')};
  transform: ${({ isOpen }) => (isOpen ? 'translate(-50%, -50%)' : 'translate(-50%, 100%)')};
  background-color: ${COLORS.WHITE};
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100vw - 60px);
  z-index: ${LEVEL.MODAL_BASIC};
  transition: all 200ms ease;
`;

const ShoesImg = styled.img`
  width: 82px;
  height: 82px;
  position: absolute;
  top: -37px;
`;

const Title = styled.p`
  margin-top: 63.5px;
  font-family: Noto Sans KR;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  letter-spacing: -0.5px;
  color: ${COLORS.BLACK};
  margin-bottom: 8px;

  span {
    margin-right: 1px;
    font-family: Campton;
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: -0.5px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const Kcal = styled.p`
  font-family: Campton;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.5px;
`;

const KcalDesc = styled.p`
  font-family: Noto Sans KR;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: -0.5px;
  margin-left: 4px;
`;

const Desc = styled.p`
  font-family: Noto Sans KR;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: -0.5px;
  color: ${COLORS.PRIMITIVES_GRAY_500};
  margin-bottom: 33.5px;
`;

const Button = styled.button`
  width: 100%;
  height: 46px;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: -0.5px;
  color: ${COLORS.BLACK};
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;
