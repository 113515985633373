import { lazy, Suspense } from 'react';
import styled from 'styled-components';
import { ErrorBoundary } from 'react-error-boundary';
import { COLORS } from '@styles/constants/_colors';
import TodayNutrientsQuestionTooltip from './components/molecules/TodayNutrientsQuestionTooltip';
import SpinnerInBox from '@components/SpinnerInBox';
import useConfigQuery from '@hooks/queries/useConfigQuery';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import { getCookie, setCookie } from '@utils/cookie';
import { useNavigate } from 'react-router-dom';
import { noneDraggable } from '@styles/mixins/_extends';
import { useCoordinate } from '@hooks/useCoordinate';
import { useRecoilState } from 'recoil';
import { dateState } from '@states/DateState';

const CharacterTheme = lazy(() => import('@pages/main/TodayNutrients/CharacterTheme'));
const DashboardTheme = lazy(() => import('@pages/main/TodayNutrients/DashboardTheme'));

const BUBBLE_CLICK_KEY = 'isSummaryBubbleClick';

const TodayNutrients = () => {
  const navigate = useNavigate();
  const { selectedTheme } = useConfigQuery();
  const isBubbleClicked = getCookie(BUBBLE_CLICK_KEY);
  const [date, setDate] = useRecoilState(dateState);
  const { setCoordinate } = useCoordinate();

  const usageTheme = () => {
    if (selectedTheme?.type === '대시보드형') return <DashboardTheme />;

    return <CharacterTheme />;
  };

  const handleCollectionModalOpen = () => {
    if (!isBubbleClicked) {
      setCookie(BUBBLE_CLICK_KEY, 'true');
    }

    sendReactNativeMessage({
      type: 'summary',
      service: 'summary',
      payload: {
        selectedDate: date,
      },
    });
  };

  const handleMoveToNutrientDetail = () => {
    setCoordinate();
    navigate('/home/mealDetail/daily');
  };

  return (
    <ErrorBoundary
      fallback={
        <LoadingContainer>
          <SpinnerInBox height="493px" />
        </LoadingContainer>
      }
    >
      <Suspense
        fallback={
          <LoadingContainer>
            <SpinnerInBox height="493px" />
          </LoadingContainer>
        }
      >
        <Wrapper $themeColor={selectedTheme?.color1 ?? ''}>
          <Header>
            <TitleBox>
              <TextUnit>나의 하루</TextUnit>
              <TodayNutrientsQuestionTooltip themeColor={selectedTheme?.color2} />
            </TitleBox>
            <SummaryTabBox>
              <SummaryTabUnit $selected $color={selectedTheme?.color3 ?? '#4A85BC'}>
                간단
              </SummaryTabUnit>
              <SummaryTabUnit
                $color={selectedTheme?.color3 ?? '#4A85BC'}
                onClick={handleMoveToNutrientDetail}
              >
                상세
              </SummaryTabUnit>
              <SummaryTabUnit
                $color={selectedTheme?.color3 ?? '#4A85BC'}
                onClick={handleCollectionModalOpen}
              >
                한눈에
              </SummaryTabUnit>
            </SummaryTabBox>
          </Header>
          {usageTheme()}
        </Wrapper>
      </Suspense>
    </ErrorBoundary>
  );
};

export default TodayNutrients;

const Wrapper = styled.div<{ $themeColor: string }>`
  display: flex;
  flex-direction: column;
  padding: 23px 0 22px;
  background-color: ${({ $themeColor }) => $themeColor};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 22px;
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2px;
`;

const SummaryTabBox = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  transform: translateY(-1px);
`;

const SummaryTabUnit = styled.li<{ $selected?: boolean; $color: string }>`
  font-family: 'Noto Sans KR';
  font-size: 17px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: ${({ $selected, $color }) => ($selected ? COLORS.WHITE : $color)};

  ${noneDraggable};
`;

const TextUnit = styled.p`
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: ${COLORS.WHITE};
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 683px;
  background-color: ${COLORS.PRIMITIVES_GRAY_60};
`;
