import { useRecoilState } from 'recoil';
import { dateState } from 'states/DateState';

import ThemeContainer from 'components/Molecules/ThemeConTainer';

import WeightDiary from 'pages/main/MyChange/WeightDiary';
import ConditionDiary from 'pages/main/MyChange/ConditionDiary';
import { myChangeTabIndexState } from 'pages/main/states';
import React, { useLayoutEffect, useRef } from 'react';
import { usePrevious } from 'react-use';
import BodyCheck from '@pages/main/MyChange/bodyCheck';
import AreaFooter from '@pages/main/elements/AreaFooter';
import { AlbumIcon } from '@assets/svgs/_Icons';
import { COLORS } from '@styles/constants/_colors';
import styled from 'styled-components';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import usePostMessageReceiver from '@hooks/usePostMessageReceiver';
import useTopModal from '@hooks/useTopModal';
import PhotoRequirementModal from '@components/modal/PhotoRequirementModal';
import usePhotoPermissionCheck from '@hooks/usePhotoPermissionCheck';

const MyChange = () => {
  const albumOpened = useRef(false);

  const { openModal } = useTopModal();
  const [tabIndex, setTabIndex] = useRecoilState(myChangeTabIndexState);
  const [date] = useRecoilState(dateState);
  const preDate = usePrevious(date);

  const { requestPhotoPermission } = usePhotoPermissionCheck();

  const footerInfo = {
    icon: <AlbumIcon />,
    text: '눈바디 사진 한눈에 보기',
    button: {
      text: '눈바디 앨범',
      onClick: () => {
        albumOpened.current = true;
        requestPhotoPermission();
      },
      bgColor: COLORS.PRIMITIVES_APRICOT_500,
    },
  };

  const tabList = [
    {
      name: '체중',
      id: 'theme_weightDiary',
    },
    {
      name: '눈바디',
      id: 'theme_bodyCheck',
    },
    {
      name: '컨디션',
      id: 'theme_condition',
    },
  ];

  usePostMessageReceiver((event: any) => {
    const { type, data } = JSON.parse(event.data);

    if (type === 'photoRequestResult') {
      if (Object.values(data).every((v) => v === 'granted' || v === 'limited')) {
        if (albumOpened.current) {
          sendReactNativeMessage({
            type: 'body-check-gallery-open',
            payload: {
              selectedDate: date,
            },
          });
        }
      } else {
        openModal(PhotoRequirementModal, {});
      }
    }
    albumOpened.current = false;
  });

  useLayoutEffect(() => {
    if (preDate === undefined || preDate === date) return;
    setTabIndex(0);
  }, [date, preDate]);

  return (
    <ThemeContainer
      mainTitle="나의 변화"
      bgColor="apricot"
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      tabList={tabList}
      minHeight="540"
      paddingBottom="30"
    >
      {tabIndex === 0 && <WeightDiary />}
      {tabIndex === 1 && <BodyCheck />}
      {tabIndex === 2 && <ConditionDiary />}

      {tabIndex === 1 && <AreaFooter footerInfo={footerInfo} />}
    </ThemeContainer>
  );
};

const FooterBox = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export default MyChange;
