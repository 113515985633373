import { forwardRef, useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '@styles/constants/_colors';
import { MyRoomItemsType } from '@models/myRoom';
import { LayerUpWhiteIcon, LayerDownWhiteIcon } from '@assets/svgs/_Icons';

interface Props {
  items: MyRoomItemsType[];
  addToSidebarItemRefs: (el: HTMLDivElement | null, index: number) => void;
  selectedItemId: string | null;
  onSelectItem: (id: string) => void;
  onLayerUp: () => void;
  onLayerDown: () => void;
}

const RoomEditorSidebar = forwardRef<HTMLDivElement, Props>(
  ({ items, addToSidebarItemRefs, selectedItemId, onSelectItem, onLayerUp, onLayerDown }, ref) => {
    useLayoutEffect(() => {
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.removeProperty('overflow');
      };
    }, []);

    return (
      <Wrapper className="editor-menu">
        <SideBar>
          <Field ref={ref}>
            {items
              .slice()
              .reverse()
              .map((item, index) => {
                const originalIndex = items.length - 1 - index;
                return (
                  <ItemBox
                    key={item.id}
                    ref={(el) => addToSidebarItemRefs(el, originalIndex)}
                    data-id={item.id}
                    isSelected={item.id === selectedItemId}
                    onClick={() => onSelectItem(item.id)}
                    onDoubleClick={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  >
                    <Item
                      src={item.img_thumb}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onDoubleClick={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </ItemBox>
                );
              })}
          </Field>
        </SideBar>
        <LayerMenuField className="editor-menu">
          <ClickRange onClick={onLayerUp}>
            <LayerControlButton>
              <LayerUpWhiteIcon className="layer-icon" />
            </LayerControlButton>
          </ClickRange>
          <ClickRange padding="4px 4px 12px 8px" onClick={onLayerDown}>
            <LayerControlButton>
              <LayerDownWhiteIcon className="layer-icon" />
            </LayerControlButton>
          </ClickRange>
        </LayerMenuField>
      </Wrapper>
    );
  },
);

export default RoomEditorSidebar;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

// 웹뷰에서 flex-direction: column-reverse 후 last-of-type에서 margin 이 안먹히는 이슈가 있어서 items배열을 reverse()함
const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 72px;
  height: 404px;
  max-height: 404px;
  border-radius: 12px;
  background-color: ${COLORS.WHITE};
  box-shadow: 3px 2px 8px 0px rgba(0, 0, 0, 0.15);
  user-select: none;
  touch-action: auto;
  overflow-y: auto;
  will-change: transform;
`;

const Field = styled.div`
  overflow-y: auto;
  > div:first-of-type {
    margin-top: 10px;
  }
  > div:last-of-type {
    margin-bottom: 10px;
  }
`;

const ItemBox = styled.div<{ isSelected?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 52px;
  min-height: 52px;
  background-color: ${COLORS.WHITE};
  will-change: transform;
  border-radius: 10px;

  ${({ isSelected }) =>
    isSelected &&
    css`
      ::after {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        width: 52px;
        height: 52px;
        border: 2px solid black;
        border-radius: 12px;
      }
    `}
`;

const Item = styled.img`
  width: 44px;
  height: 44px;
  object-fit: contain;
  position: relative;
  user-select: none;
  border-radius: 8px;
`;

const LayerMenuField = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -38px;
  bottom: -12px;
`;

const ClickRange = styled.div<{ padding?: string }>`
  padding: ${({ padding }) => (padding ? padding : '12px 4px 4px 8px')};
`;

const LayerControlButton = styled.button`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  box-shadow: 3.25px 2.17px 8.67px 0px #00000026;

  .layer-icon {
    &:active {
      opacity: 0.4;
    }
  }
`;
