import { AcquisitionType, MyRoomType } from '@models/myRoom';
import { atom, DefaultValue, selector } from 'recoil';

export const myRoomFieldSizeState = atom({
  key: 'myRoomFieldSizeState',
  default: {
    width: 0,
    height: 0,
  },
});

// 기본 벽지, 바닥 스킨 상태
// (아이템들은 삭제하면 끝이지만 바닥, 벽지는 삭제하면 원래 스킨으로 롤백을 해야하므로 상태로 저장)
export const myRoomDefaultItemState = atom({
  key: 'myRoomDefaultItemState',
  default: {
    room_wallpaper_idx: -1,
    room_wallpaper_img_origin: '',
    room_wallpaper_name: '',
    room_wallpaper_img_origin_width: 1125,
    room_wallpaper_img_origin_height: 870,
    room_floor_idx: -1,
    room_floor_img_origin: '',
    room_floor_name: '',
    room_floor_img_origin_width: 1125,
    room_floor_img_origin_height: 591,
    color_floor: '',
  },
});

export const initMyRoomState = {
  roomId: null,
  roomName: '',
  colorFloor: '',
  wallpaper: {
    id: 'wallpaper',
    type: '벽지',
    room_item_idx: -1,
    name: '기본 벽지',
    img_origin: '',
    img_thumb: '',
    img_details: [],
    width: 1125,
    height: 870,
    purchased: true,
    price: 0,
    sale_price: 0,
    sale_percent: 0,
    liked: null,
    likes: 0,
    acquisition_type: 'common' as AcquisitionType,
    limited_start: '',
    limited_end: '',
  },
  floor: {
    id: 'floor',
    type: '바닥',
    room_item_idx: -1,
    name: '기본 바닥',
    img_origin: '',
    img_thumb: '',
    img_details: [],
    width: 1125,
    height: 591,
    purchased: true,
    price: 0,
    sale_price: 0,
    sale_percent: 0,
    liked: null,
    likes: 0,
    acquisition_type: 'common' as AcquisitionType,
    limited_start: '',
    limited_end: '',
  },
  items: [
    {
      id: 'character',
      type: '캐릭터',
      room_item_idx: -1,
      name: 'character',
      x: 110,
      y: 210,
      img_origin: '',
      img_thumb: '',
      img_details: [],
      width: 465,
      height: 372,
      purchased: true,
      price: 0,
      sale_price: 0,
      sale_percent: 0,
      liked: null,
      likes: 0,
      acquisition_type: 'common' as AcquisitionType,
      limited_start: '',
      limited_end: '',
    },
  ],
};

export const myRoomState = atom<MyRoomType>({
  key: 'myRoomState',
  default: initMyRoomState,
});

export const uniqueCartRoomItemsFilterState = atom({
  key: 'uniqueCartRoomItemsFilterState',
  default: '삭제',
});

// 구매 필요 리스트 (myRoomState에서 중복만 제거한 상태)
export const uniqueCartRoomItemsState = selector({
  key: 'uniqueCartRoomItemsState',
  get: ({ get }) => {
    const myRoom = get(myRoomState);
    // 구매 필요한 아이템 필터
    const cartRoomItem = [myRoom.wallpaper, myRoom.floor, ...myRoom.items].filter(
      (item) => !item.purchased,
    );

    const roomItemIdxCount = cartRoomItem.reduce((acc: { [key: number]: number }, item) => {
      acc[item.room_item_idx] = (acc[item.room_item_idx] || 0) + 1;
      return acc;
    }, {});

    const uniqueItems = cartRoomItem.filter((item, index, self) => {
      // room_item_idx가 1회 등장한 아이템은 그대로 유지
      if (roomItemIdxCount[item.room_item_idx] === 1) {
        return true;
      }

      // room_item_idx가 2회 이상인 경우, 해당 room_item_idx의 첫 번째 항목만 포함(중복 제거)
      return self.findIndex((i) => i.room_item_idx === item.room_item_idx) === index;
    });

    return uniqueItems;
  },

  set: ({ get, set, reset }, selectedValues) => {
    if (selectedValues instanceof DefaultValue) {
      reset(myRoomState);
      return;
    }

    const uniqueCartRoomItemsFilter = get(uniqueCartRoomItemsFilterState);
    const myRoomDefaultItem = get(myRoomDefaultItemState);

    switch (uniqueCartRoomItemsFilter) {
      case '삭제': {
        // 장바구니에서 아이템 삭제할 때 myRoomState 가공
        set(myRoomState, (prev) => {
          let updatedState = { ...prev };
          // 벽지랑 바닥은 cart에서 제거할 때 기본 벽지, 바닥으로 다시 세팅해줘야함
          selectedValues.forEach((value) => {
            if (value.type === '벽지') {
              updatedState = {
                ...updatedState,
                wallpaper: {
                  id: initMyRoomState.wallpaper.id,
                  type: initMyRoomState.wallpaper.type,
                  room_item_idx: myRoomDefaultItem.room_wallpaper_idx,
                  name: myRoomDefaultItem.room_wallpaper_name,
                  img_origin: myRoomDefaultItem.room_wallpaper_img_origin,
                  img_thumb: initMyRoomState.wallpaper.img_thumb,
                  img_details: initMyRoomState.wallpaper.img_details,
                  width: myRoomDefaultItem.room_wallpaper_img_origin_width,
                  height: myRoomDefaultItem.room_wallpaper_img_origin_height,
                  purchased: initMyRoomState.wallpaper.purchased,
                  price: initMyRoomState.wallpaper.price,
                  sale_price: initMyRoomState.wallpaper.sale_price,
                  sale_percent: initMyRoomState.wallpaper.sale_percent,
                  liked: initMyRoomState.wallpaper.liked,
                  likes: initMyRoomState.wallpaper.likes,
                  acquisition_type: initMyRoomState.wallpaper.acquisition_type,
                  limited_start: initMyRoomState.wallpaper.limited_start,
                  limited_end: initMyRoomState.wallpaper.limited_end,
                },
              };
            } else if (value.type === '바닥') {
              updatedState = {
                ...updatedState,
                colorFloor: myRoomDefaultItem.color_floor,
                floor: {
                  id: initMyRoomState.floor.id,
                  type: initMyRoomState.floor.type,
                  room_item_idx: myRoomDefaultItem.room_floor_idx,
                  name: myRoomDefaultItem.room_floor_name,
                  img_origin: myRoomDefaultItem.room_floor_img_origin,
                  img_thumb: initMyRoomState.floor.img_thumb,
                  img_details: initMyRoomState.floor.img_details,
                  width: myRoomDefaultItem.room_floor_img_origin_width,
                  height: myRoomDefaultItem.room_floor_img_origin_height,
                  purchased: initMyRoomState.floor.purchased,
                  price: initMyRoomState.floor.price,
                  sale_price: initMyRoomState.floor.sale_price,
                  sale_percent: initMyRoomState.floor.sale_percent,
                  liked: initMyRoomState.floor.liked,
                  likes: initMyRoomState.floor.likes,
                  acquisition_type: initMyRoomState.floor.acquisition_type,
                  limited_start: initMyRoomState.floor.limited_start,
                  limited_end: initMyRoomState.floor.limited_end,
                },
              };
            } else {
              // 다른 타입의 아이템은 items 배열에서 필터링
              updatedState = {
                ...updatedState,
                items: updatedState.items.filter(
                  (item) => value.room_item_idx !== item.room_item_idx,
                ),
              };
            }
          });
          return updatedState;
        });
        return;
      }
      case '구매': {
        // 장바구니에서 아이템 구매할 때 myRoomState 가공
        set(myRoomState, (prev) => {
          let updatedState = { ...prev };

          selectedValues.forEach((value) => {
            if (value.type === '벽지') {
              updatedState = {
                ...updatedState,
                wallpaper: {
                  ...updatedState.wallpaper,
                  purchased: true,
                },
              };
            } else if (value.type === '바닥') {
              updatedState = {
                ...updatedState,
                floor: {
                  ...updatedState.floor,
                  purchased: true,
                },
              };
            } else {
              updatedState = {
                ...updatedState,
                items: updatedState.items.map((item) =>
                  value.room_item_idx === item.room_item_idx
                    ? {
                        ...item,
                        purchased: true,
                      }
                    : item,
                ),
              };
            }
          });

          return updatedState;
        });
        return;
      }
      default:
        break;
    }
  },
});

export const myRoomCurrentMainCategoryState = atom<string>({
  key: 'myRoomCurrentMainCategoryState',
  default: '마이',
});

export const myRoomCurrentSubCategoryState = atom<string>({
  key: 'myRoomCurrentSubCategoryState',
  default: '전체',
});

export const myRoomItemListBottomSheetScrollState = atom({
  key: 'myRoomItemListBottomSheetScrollState',
  default: {
    mainCategoryScrollX: 0,
    subCategoryScrollX: 0,
    scrollY: {
      purchased: 0,
      liked: 0,
      categories: 0,
    },
  },
});

export const myRoomInfoState = atom({
  key: 'myRoomInfoState',
  default: {
    roomName: '',
    thumbnail: '',
    likes: 0,
  },
});
