import { SVGProps } from 'react';
import { COLORS, FOUNDATION_COLOR } from '@styles/constants/_colors';

interface Props extends SVGProps<SVGSVGElement> {
  size?: 's' | 'm' | 'l';
  active?: boolean;
}

// 기존 LikeHeart.tsx 파일을 디자인 시스템 기준에 맞게 LikeIcon.tsx로 변경
const LikeIcon = ({ size = 's', active, ...props }: Props) => {
  const iconColor = active ? FOUNDATION_COLOR.PRIMARY_PINK : COLORS.PRIMITIVES_GRAY_400;

  switch (size) {
    case 's':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          {...props}
        >
          <path
            d="M6.4416 12.3736C6.77138 12.6037 7.22845 12.6037 7.55824 12.3736C17.3506 5.53949 11.6973 0.0304101 7.81285 2.5937C7.33059 2.91193 6.66925 2.91193 6.18699 2.5937C2.30251 0.0304108 -3.35076 5.53949 6.4416 12.3736Z"
            fill={iconColor}
            stroke={iconColor}
            strokeWidth="1.35"
            strokeLinejoin="round"
          />
          <path
            d="M10.5 3.61719C11.5 4.11719 11.5 5.11719 11.5 5.61719"
            stroke="white"
            strokeLinecap="round"
          />
        </svg>
      );
    case 'm':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          {...props}
        >
          <path
            d="M11.0427 21.0218C11.6081 21.4164 12.3916 21.4164 12.957 21.0218C29.7439 9.30625 20.0526 -0.137878 13.3935 4.25633C12.5667 4.80187 11.433 4.80187 10.6063 4.25633C3.94716 -0.137878 -5.74416 9.30625 11.0427 21.0218Z"
            fill={iconColor}
            stroke={iconColor}
            strokeWidth="2.31429"
            strokeLinejoin="round"
          />
          <path
            d="M18 6.00977C19.7143 6.86691 19.7143 8.58119 19.7143 9.43834"
            stroke="white"
            strokeWidth="1.71429"
            strokeLinecap="round"
          />
        </svg>
      );
    case 'l':
      return (
        <svg
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M12.2829 22.9204C12.8969 23.3489 13.7478 23.3489 14.3618 22.9204C32.5765 10.2079 22.0622 -0.039866 14.836 4.72642C13.938 5.31872 12.7067 5.31873 11.8087 4.72642C4.58249 -0.0398688 -5.93174 10.2079 12.2829 22.9204Z"
            fill={iconColor}
            stroke={iconColor}
            strokeWidth="2.51344"
            strokeLinejoin="round"
          />
          <path
            d="M19.834 6.63184C21.6943 7.56199 21.6943 9.42231 21.6943 10.3525"
            stroke="white"
            strokeWidth="1.86181"
            strokeLinecap="round"
          />
        </svg>
      );
  }
};

export default LikeIcon;
