import ReactS3Client from 'react-aws-s3-typescript';
import imageCompression from 'browser-image-compression';
import { ENV, S3CONFIG } from '@shared/link';
import RuntimeError from 'utils/RuntimeError';
import dayjs from 'dayjs';

import AWS from 'aws-sdk';

type Path =
  | 'recipe'
  | 'community'
  | 'ketogram'
  | 'profile'
  | 'ketogram-title'
  | 'chat'
  | 'summary'
  | 'group-profile'
  | 'food-report'
  | 'message'
  | 'room';

const awsS3 = new AWS.S3({
  accessKeyId: S3CONFIG.accessKeyId,
  secretAccessKey: S3CONFIG.secretAccessKey,
  region: S3CONFIG.region,
});

const s3 = (path: string) => new ReactS3Client({ ...S3CONFIG, dirName: path });
const date = dayjs().format('YYYY-MM-DD');
const REVERT_MINE_TYPE = ['heic', 'HEIC', 'heif', 'HEIF'];

interface UploadJsonType {
  jsonData: object;
  name: string;
  path: Path;
}

interface UploadType {
  blob: File;
  name: string;
  path: Path;
  isSmall?: boolean;
  isResize?: boolean;
}

const handleLimitAndSetToast = async (file: File) => {
  if (file.size > 10485760) {
    throw new RuntimeError({
      code: 4000,
      name: 'exceed error',
      message: 'Image limit size exceeded',
    });
  }
};

const getFilesS3 = async (path: string) => {
  const params = {
    Bucket: 'inout-posting-imgs', // S3 버킷 이름
    Prefix: path, // 폴더 경로
  };

  try {
    return await awsS3.listObjectsV2(params).promise();
  } catch (error) {
    console.log('s3 file fetch error: ', error);
  }
};

const uploadS3 = async ({ blob, name, path }: UploadType) => {
  const file = await revertToJpg(blob);
  const data = await s3(path).uploadFile(file, name);

  return data.location;
};

const deleteS3 = async (name: string, path: Path) => {
  const data = await s3(path).deleteFile(name);
  return data;
};

const altNameS3 = () =>
  date + `${'inout-posting-imgs'}` + Date.now() + parseInt(`${Math.random() * (99 - 10) + 10}`);

const revertToJpg = async (imgFile: File) => {
  let file = imgFile;
  const nameList = file.name.split('.');
  const revertTarget = REVERT_MINE_TYPE.find(
    (mineType) => nameList[nameList.length - 1] === mineType,
  );

  if (revertTarget) {
    const options = {
      fileType: 'image/jpeg', // 변환할 파일 형식
    };
    const compressedFile = await imageCompression(imgFile, options);
    file = new File([compressedFile], file.name.split('.').slice(0, -1).join('.') + '.jpg', {
      type: 'image/jpeg',
      lastModified: new Date().getTime(),
    });
  }

  return file;
};

// ----- JSON -----
const uploadJsonToS3 = async ({ jsonData, name, path }: UploadJsonType) => {
  // JSON 데이터를 Blob으로 변환
  const jsonBlob = new Blob([JSON.stringify(jsonData)], { type: 'application/json' });

  // Blob을 File 객체로 변환
  const jsonFile = new File([jsonBlob], 'json', {
    type: 'application/json',
    lastModified: new Date().getTime(),
  });

  // S3에 업로드
  const data = await s3(`${path}/${ENV}`).uploadFile(jsonFile, name);
  return data.location;
};

// ----- Data URL to Blob 변환 -----
const dataURLtoBlob = (dataUrl: string) => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

// ----- 캔버스 이미지 업로드 -----
const uploadCanvasToS3 = async (dataUrl: string, name: string, path: Path) => {
  // dataUrl을 Blob으로 변환
  const blob = dataURLtoBlob(dataUrl);

  // Blob을 File 객체로 변환
  const file = new File([blob], `${name}.png`, {
    type: 'image/png',
    lastModified: new Date().getTime(),
  });

  // S3에 업로드
  const data = await s3(`${path}/${ENV}`).uploadFile(file, name);

  return data.location;
};

export {
  getFilesS3,
  uploadS3,
  deleteS3,
  altNameS3,
  revertToJpg,
  handleLimitAndSetToast,
  uploadJsonToS3,
  uploadCanvasToS3,
};
