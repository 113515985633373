import { get, patch } from '../modules/HttpWebClient/default';
import {
  MyRoomMainCategory,
  MyRoomSubCategory,
  StoreMyRoom,
  StoreSortType,
  StoreTheme,
} from '@models/store';

export const getStoreThemeList = async (memberIdx: number, orderBy?: StoreSortType) => {
  const url = `/v2/members/${memberIdx}/theme`;
  const { data } = await get<StoreTheme[]>(url, {
    params: {
      order_by: orderBy,
    },
  });

  return data;
};

export const getThemeWishlist = async (memberIdx: number) => {
  const url = `/v2/members/${memberIdx}/theme/like`;
  const { data } = await get<StoreTheme[]>(url);

  return data;
};

export const getThemePurchasedList = async (memberIdx: number) => {
  const url = `/v2/members/${memberIdx}/theme/purchased`;
  const { data } = await get<StoreTheme[]>(url);

  return data;
};

export const patchThemePurchase = async (memberIdx: number, themeIdx: number, timezone: string) => {
  const url = `/v2/members/${memberIdx}/theme/${themeIdx}:purchase?timezone=${timezone}`;

  const { status } = await patch(url);

  return status === 200;
};

export const patchThemeLike = async (memberIdx: number, themeIdx: number) => {
  const url = `/v2/members/${memberIdx}/theme/${themeIdx}:like`;
  await patch(url);

  return themeIdx;
};

export const patchThemeLikeCancel = async (memberIdx: number, themeIdx: number) => {
  const url = `/v2/members/${memberIdx}/theme/${themeIdx}:like-cancel`;
  await patch(url);

  return themeIdx;
};

export const getMyRoomMainCategoryList = async (memberIdx: number) => {
  const url = `/v2/members/${memberIdx}/room/items/main`;
  const { data } = await get<MyRoomMainCategory[]>(url);

  return data;
};

export const getMyRoomSubCategoryList = async (memberIdx: number, categoryMainTitle: string) => {
  const url = `/v2/members/${memberIdx}/room/items/sub`;
  const { data } = await get<MyRoomSubCategory[]>(url, {
    params: {
      title: categoryMainTitle,
    },
  });

  return data;
};

export const getStoreMyRoomList = async (
  memberIdx: number,
  title: string,
  type: string,
  orderBy: StoreSortType,
  from = 0,
) => {
  const url = `/v2/members/${memberIdx}/room/items`;
  const { data } = await get<StoreMyRoom[]>(url, {
    params: {
      title,
      type,
      order_by: orderBy,
      from,
      size: 10,
    },
  });

  return data;
};

export const getStoreMyRoomWishlist = async (memberIdx: number) => {
  const url = `/v2/members/${memberIdx}/room/items/like`;
  const { data } = await get<StoreMyRoom[]>(url);

  return data;
};

export const getStoreMyRoomPurchasedList = async (memberIdx: number) => {
  const url = `/v2/members/${memberIdx}/room/items/purchased`;
  const { data } = await get<StoreMyRoom[]>(url);

  return data;
};

export const patchMyRoomPurchase = async (
  memberIdx: number,
  roomItemIdx: number,
  timezone: string,
) => {
  const url = `/v2/members/${memberIdx}/room/items/${roomItemIdx}:purchase?timezone=${timezone}`;
  const { status } = await patch(url);

  return status === 200;
};

export const patchMyRoomLike = async (memberIdx: number, roomItemIdx: number) => {
  const url = `/v2/members/${memberIdx}/room/items/${roomItemIdx}:like`;

  await patch(url);

  return roomItemIdx;
};

export const patchMyRoomLikeCancel = async (memberIdx: number, roomItemIdx: number) => {
  const url = `/v2/members/${memberIdx}/room/items/${roomItemIdx}:like-cancel`;
  await patch(url);

  return roomItemIdx;
};

export const patchRoomLike = async (memberIdx: number, roomId: string) => {
  const url = `/v2/members/${memberIdx}/room/${roomId}:like`;
  await patch(url);

  return roomId;
};

export const patchRoomLikeCancel = async (memberIdx: number, roomId: string) => {
  const url = `/v2/members/${memberIdx}/room/${roomId}:like-cancel`;
  await patch(url);

  return roomId;
};
