import axios from 'axios';
import { MyRoomJsonType } from '@models/myRoom';
import { ENV } from '@shared/link';
import { initMyRoomState, myRoomDefaultItemState, myRoomState } from '@states/myRoomState';
import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { useState } from 'react';

const useGetRoomJson = (roomId?: string) => {
  const setMyRoom = useSetRecoilState(myRoomState);
  const setMyRoomDefaultItem = useSetRecoilState(myRoomDefaultItemState);
  const [imagesLoading, setImagesLoading] = useState<boolean[]>([]);
  const isAllJsonImageLoading = !imagesLoading.every((loading) => loading);
  // 이미지 로딩 완료 핸들러
  const handleImageLoad = (index: number) => {
    setImagesLoading((prevSources) => {
      const newSources = [...prevSources];
      newSources[index] = true; // 로딩 완료된 이미지를 true로 설정
      return newSources;
    });
  };

  // 이미지 프리로드 및 로딩 상태 설정 함수
  const preloadImages = (urls: string[]) => {
    const loadingStatusArray = Array(urls.length).fill(false); // false로 초기화된 배열 생성
    setImagesLoading(loadingStatusArray); // 이미지 로딩 상태 배열 설정

    urls.forEach((url, index) => {
      const img = new Image();
      img.src = url;
      img.onload = () => handleImageLoad(index); // 이미지 로드 완료 시 핸들러 호출
    });
  };

  const query = useQuery<MyRoomJsonType, unknown, MyRoomJsonType>({
    queryKey: ['myRoom', 'json', roomId],
    queryFn: async () => {
      const noCacheUrl = `https://inout-posting-imgs.s3.ap-northeast-2.amazonaws.com/room/${ENV}/${roomId}.json?_=${Date.now()}`;
      const { data } = await axios.get(noCacheUrl);
      return data;
    },
    enabled: !!roomId,
    cacheTime: 0,
    retry: 0,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      preloadImages([
        data.wallpaper.img_origin,
        data.floor.img_origin,
        ...data.items.map((item) => item.img_origin),
      ]);

      setMyRoomDefaultItem({
        room_wallpaper_idx: data.wallpaper.room_item_idx,
        room_wallpaper_img_origin: data.wallpaper.img_origin,
        room_wallpaper_name: data.wallpaper.name,
        room_wallpaper_img_origin_width: data.wallpaper.width,
        room_wallpaper_img_origin_height: data.wallpaper.height,
        room_floor_idx: data.floor.room_item_idx,
        room_floor_img_origin: data.floor.img_origin,
        room_floor_name: data.floor.name,
        room_floor_img_origin_width: data.floor.width,
        room_floor_img_origin_height: data.floor.height,
        color_floor: data.colorFloor,
      });

      setMyRoom((prev) => ({
        roomId: data.roomId,
        roomName: data.roomName,
        colorFloor: data.colorFloor,
        wallpaper: {
          ...prev.wallpaper,
          room_item_idx: data.wallpaper.room_item_idx,
          name: data.wallpaper.name,
          img_origin: data.wallpaper.img_origin,
          img_thumb: data.wallpaper.img_thumb,
          width: data.wallpaper.width,
          height: data.wallpaper.height,
          purchased: true,
        },
        floor: {
          ...prev.floor,
          room_item_idx: data.floor.room_item_idx,
          name: data.floor.name,
          img_origin: data.floor.img_origin,
          img_thumb: data.floor.img_thumb,
          width: data.floor.width,
          height: data.floor.height,
          purchased: true,
        },
        items: data.items.map((item) => ({
          ...item,
          purchased: initMyRoomState.items[0].purchased,
          price: initMyRoomState.items[0].price,
          sale_price: initMyRoomState.items[0].sale_price,
          sale_percent: initMyRoomState.items[0].sale_percent,
          liked: initMyRoomState.items[0].liked,
          likes: initMyRoomState.items[0].likes,
          acquisition_type: initMyRoomState.items[0].acquisition_type,
          limited_start: initMyRoomState.items[0].limited_start,
          limited_end: initMyRoomState.items[0].limited_end,
        })),
      }));
    },
  });

  return { ...query, isAllJsonImageLoading };
};

export default useGetRoomJson;
