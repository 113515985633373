import { get, post } from '@modules/HttpWebClient/default';
import { MainFirstDisplay, MainFourthDisplay, MainSecondDisplay } from '@models/main';

import { ResponseMsgWithStatus } from '@models/common';
import { WaterInfo } from '@models/community';
import { MainWeightResponse, MyNewsCountResponse } from '@models/dietDiary';

export async function getMainFirstDisplay(createdAt: string): Promise<MainFirstDisplay> {
  const url = `/main/first/${createdAt}`;

  const { data } = await get<MainFirstDisplay>(url);
  return data;
}

export async function getMainSecondDisplay(createdAt: string): Promise<MainSecondDisplay> {
  const url = `/main/second/${createdAt}`;

  const { data } = await get<MainSecondDisplay>(url);
  return data;
}

export async function getMainFourthDisplay(createdAt: string): Promise<MainFourthDisplay> {
  const url = `/main/fourth/${createdAt}`;

  const { data } = await get<MainFourthDisplay>(url);
  return data;
}

export async function getWaterInfo(created_at: string): Promise<WaterInfo> {
  const url = `/water/${created_at}`;
  const { data } = await get<WaterInfo>(url);
  return data;
}

export async function getStickerInfo(memberIdx: number, created_at: string) {
  const url = `/members/${memberIdx}/diaries/collect/${created_at}`;
  const { data } = await get<{ category: string; stickerName: string }>(url);
  return data;
}

export async function postWaterInfo(request: WaterInfo): Promise<ResponseMsgWithStatus> {
  const url = '/water';
  const { data } = await post<ResponseMsgWithStatus>(url, {
    ...request,
  });
  return data;
}

export async function getMainWeight(created_at: string): Promise<MainWeightResponse> {
  const url = `/weight/${created_at}`;
  const { data } = await get<MainWeightResponse>(url);
  return data;
}

export async function getMyNewsCount() {
  const url = `/news/count`;
  const { data } = await get<MyNewsCountResponse>(url);
  return data;
}
