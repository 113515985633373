/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from 'react';

interface Props {
  roomRef: React.RefObject<HTMLDivElement>;
  width: number;
  height: number;
  ratio: number;
  initialPosition: {
    x: number;
    y: number;
  };
}

const useDraggable = ({ roomRef, width, height, ratio, initialPosition }: Props) => {
  const [position, setPosition] = useState(initialPosition);

  useEffect(() => {
    // initialPosition의 x와 y 값을 비교하여 값이 다를 때만 업데이트
    if (position.x !== initialPosition.x || position.y !== initialPosition.y) {
      setPosition(initialPosition);
    }
  }, [initialPosition.x, initialPosition.y]);

  const dragStartPosition = useRef<{ x: number; y: number } | null>(null);

  const handleTouchStart = (event: React.TouchEvent<HTMLElement>) => {
    if (event.touches.length === 1) {
      dragStartPosition.current = {
        x: event.touches[0].clientX - position.x,
        y: event.touches[0].clientY - position.y,
      };
    }
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLElement>) => {
    if (dragStartPosition.current && roomRef.current) {
      const roomRect = roomRef.current.getBoundingClientRect();
      let newX = event.touches[0].clientX - dragStartPosition.current.x;
      let newY = event.touches[0].clientY - dragStartPosition.current.y;

      const minX = -(width / 2) / ratio;
      const maxX = (roomRect.width - width / 2) / ratio;
      const minY = -(height / 2) / ratio;
      const maxY = (roomRect.height - height / 2) / ratio;

      newX = Math.min(maxX, Math.max(newX, minX));
      newY = Math.min(maxY, Math.max(newY, minY));

      setPosition({ x: newX, y: newY });
    }
  };

  const handleTouchEnd = () => {
    dragStartPosition.current = null;
  };

  return {
    position,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  };
};

export default useDraggable;
