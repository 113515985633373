import {
  Cash1Icon,
  Cash2Icon,
  Cash3Icon,
  Cash4Icon,
  Cash5Icon,
  Cash6Icon,
  Cash7Icon,
  Discount2Icon,
  Discount3Icon,
  Discount4Icon,
  Discount5Icon,
  Discount6Icon,
  Discount7Icon,
} from '@assets/svgs';

export interface ProductViewType {
  count: number;
  title: string;
  price: string;
  name: string;
  icon: React.ReactNode;
  discount?: number;
  discountIcon?: React.ReactNode;
}

export const renderProduct: ProductViewType[] = [
  {
    title: '사과 200개',
    count: 200,
    icon: <Cash1Icon />,
    price: '2000',
    name: 'v2.inout.apple.1',
  },
  {
    title: '사과 400개',
    count: 400,
    icon: <Cash2Icon />,
    discount: 13,
    discountIcon: <Discount2Icon />,
    price: '3500',
    name: 'v2.inout.apple.2',
  },
  {
    title: '사과 700개',
    count: 700,
    icon: <Cash3Icon />,
    price: '5800',
    discount: 17,
    discountIcon: <Discount3Icon />,
    name: 'v2.inout.apple.3',
  },
  {
    title: '사과 1000개',
    count: 1000,
    icon: <Cash4Icon />,
    price: '7900',
    discount: 21,
    discountIcon: <Discount4Icon />,
    name: 'v2.inout.apple.4',
  },
  {
    title: '사과 1300개',
    count: 1300,
    icon: <Cash5Icon />,
    price: '9900',
    discount: 24,
    discountIcon: <Discount5Icon />,
    name: 'v2.inout.apple.5',
  },
  {
    title: '사과 1600개',
    count: 1600,
    icon: <Cash6Icon />,
    price: '11700',
    discount: 27,
    discountIcon: <Discount6Icon />,
    name: 'v2.inout.apple.6',
  },
  {
    title: '사과 2000개',
    count: 2000,
    icon: <Cash7Icon />,
    price: '13900',
    discount: 31,
    discountIcon: <Discount7Icon />,
    name: 'v2.inout.apple.7',
  },
];
