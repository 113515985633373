import { ChangeEvent, useCallback, useLayoutEffect, useState } from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import ButtonSet from 'components/Atoms/Button/ButtonSet';
import Portal from 'components/portal';
import DeleteDefaultButton from '@components/Molecules/DeleteDefaultButton';
import ModalBottomButton from '@components/elements/Button/ModalBottomButton';
import styled from 'styled-components';

type convertHandlers = {
  [key: string]: () => void;
  '00': () => void;
  default: () => void;
};

interface Props {
  walk: number;
  closeModal: () => void;
  onSubmit: (value: number) => void;
  onDelete: () => void;
}
export default function WalkingModal({ walk, closeModal, onSubmit, onDelete }: Props) {
  const classes = useStyle();
  const [value, setValue] = useState(walk);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const data = event.target.value;
    const handleConvert: convertHandlers = {
      '00': () => {
        return;
      },
      '': () => {
        setValue(Number(event.target.value));
      },
      default: () => {
        if (data.length > 6) {
          return;
        } else {
          setValue(Number(data));
        }
      },
    };

    (handleConvert[data] || handleConvert.default)();
  }, []);

  const submitHandle = () => {
    onSubmit(Number(value));
  };

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  return (
    <Portal>
      <Grid className={classes.container}>
        <Grid className={classes.back} onClick={closeModal} />
        <Grid className={classes.paper}>
          <Grid
            className={classes.header}
            container
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <p className={classes.title}>걸음 수 입력</p>
            <DeleteDefaultButton onClick={closeModal} color={'#000'} size={'large'} />
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Grid item>
              <Input
                value={value === 0 ? '' : value}
                placeholder={'0'}
                onChange={handleChange}
                onKeyDown={(event) => {
                  if (event.key === '.' || event.key === 'e') {
                    event.preventDefault();
                  }
                }}
                type="number"
                inputMode="numeric"
              />
            </Grid>
          </Grid>
          {walk > 0 ? (
            <div className={classes.weightButtonWrapper}>
              <ButtonSet
                text="삭제하기"
                size="half"
                type="button"
                color="gray"
                onClick={onDelete}
                isActive
              />
              <ButtonSet
                text="수정하기"
                size="half"
                type="button"
                color="black"
                isActive={Number(value) > 0}
                onClick={submitHandle}
              />
            </div>
          ) : (
            <ModalBottomButton isOn={Number(value) > 0} onClick={submitHandle}>
              완료
            </ModalBottomButton>
          )}
        </Grid>
      </Grid>
    </Portal>
  );
}

const Input = styled.input`
  width: 100%;
  height: 45px;
  font-family: Campton;
  font-size: 30px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -1.1px;
  text-align: center;
`;

const useStyle = makeStyles(() =>
  createStyles({
    container: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 2204,
    },
    back: {
      width: '100%',
      height: '100%',
      background: '#000',
      opacity: 0.5,
    },
    paper: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      background: '#fff',
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
      padding: '20px 0 0',
    },
    weightButtonWrapper: {
      display: 'flex',
      gap: 7,
      padding: 12,
    },
    header: {
      padding: '0 20px 0 25px',
    },
    inputWrapper: {
      padding: '50px 0 50px',
    },
    title: {
      fontSize: 17,
      fontWeight: 500,
      lineHeight: 1,
      letterSpacing: -0.62,
    },
  }),
);
