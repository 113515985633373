import { css } from 'styled-components';

/**
 * @param clamp     (Number)                  : 텍스트를 몇줄까지 처리할지
 * @param lineHeight (Number : default 19.2)  : 텍스트 Line Height
 * @param isProtect (Boolean : default false) : 텍스트가 없어도 영역을 지킬지
 * @returns css
 */
export const multipleEllipsis = (clamp: number, lineHeight = 19.2, isProtect?: boolean) => css`
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: ${clamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-height: ${lineHeight * clamp}px;
  ${isProtect && `min-height: ${lineHeight * clamp}px`};
`;

export const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const noneDraggable = css`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
