import styled from 'styled-components';
import { ProductViewType } from '@structures/pointPurchase/renderProduct';
import { COLORS } from '@styles/constants/_colors';
import { CashUnitIcon } from '@assets/svgs';

interface Props {
  item: ProductViewType;
  onClick: () => void;
  isSelected: boolean;
}

const ProductCard = ({ item, onClick, isSelected }: Props) => {
  return (
    <Wrapper onClick={onClick} isSelected={isSelected}>
      {item.icon}
      <TextBox>
        <Row>
          <Count>{item.count}</Count>
          <CountText>개</CountText>
        </Row>
        <Row>
          {item.discount && <Discount>{item.discount}%</Discount>}
          <CashUnitIcon
            color={COLORS.PRIMITIVES_GRAY_500}
            width={14}
            height={14}
            style={{ transform: 'translateY(1px)' }}
          />
          <Price>{Number(item.price).toLocaleString()}</Price>
        </Row>
      </TextBox>
    </Wrapper>
  );
};

export default ProductCard;

const Wrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  width: 100%;
  padding: 11px 0px 11px 10px;
  border-radius: 10px;
  background-color: ${COLORS.PRIMITIVES_GRAY_60};
  box-shadow: ${({ isSelected }) => (isSelected ? `0 0 0 2px ${COLORS.BLACK}` : 'none')};
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 7px;
  row-gap: 2px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Count = styled.p`
  color: ${COLORS.BLACK};
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.5px;
`;

const CountText = styled.p`
  color: ${COLORS.BLACK};
  font-size: 15px;
  font-weight: 700;
  line-height: 120%; /* 18px */
  letter-spacing: -0.5px;
`;

const Discount = styled.p`
  color: ${COLORS.RED};
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Campton;
  font-size: 13px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.5px;
  margin-right: 2px;
`;

const Price = styled.p`
  margin-left: 1px;
  color: ${COLORS.PRIMITIVES_GRAY_500};
  font-family: Campton;
  font-size: 13px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.5px;
`;
