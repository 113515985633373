import { atom } from 'recoil';
import { MainFirstDisplay, MainFourthDisplay } from '../types/main';

export const mainFirstDisplayState = atom<MainFirstDisplay>({
  key: 'mainFirstDisplayState',
  default: {
    goals: {
      net_carbohydrate_goal: 0,
      protein_goal: 0,
      fat_goal: 0,
      calories_goal: 0,
    },
    day: {
      net_carbohydrate_day: 0,
      protein_day: 0,
      fat_day: 0,
      calories_day: 0,
      exerciseCalories: 0,
      sugar_day: 0,
      sodium_day: 0,
    },
  },
});

export const mainFourthDisplayState = atom<MainFourthDisplay>({
  key: 'mainFourthDisplayState',
  default: {} as MainFourthDisplay,
});

export const waterTotalState = atom<number>({
  key: 'waterTotal',
  default: 0,
});
