import { useQuery } from 'react-query';
import { getNavigationNews } from '@apis/communityApi';

const useNavigationNewsQuery = () => {
  return useQuery(['navigation'], getNavigationNews, {
    initialData: {
      battle: false,
      myProfile: false,
    },
  });
};

export default useNavigationNewsQuery;
