import DimmedWrapper from '@components/elements/Wrapper/DimmedWrapper';
import Portal from '@components/portal';
import { COLORS } from '@styles/constants/_colors';
import LEVEL from '@styles/constants/_levels';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

interface Props {
  headText: string;
  strongText?: string;
  subText?: string;
  children?: React.ReactNode;
  onClose?: () => void;
  onClick?: () => void;
  buttonText1?: string;
  buttonText2?: string;
}

export default function SlimPopup({
  headText,
  strongText,
  subText,
  children,
  buttonText1 = '아니요',
  buttonText2,
  onClick,
  onClose,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsOpen(true), 100);
  }, []);

  return (
    <Portal>
      <DimmedWrapper onClose={onClose}>
        <ModalField isOpen={isOpen}>
          {children ? (
            children
          ) : (
            <TextField>
              <HeadText>{headText}</HeadText>
              {subText && (
                <NameBox>
                  {strongText && <Name memberName={strongText ?? ''}>{`${strongText}`}</Name>}
                  {subText}
                </NameBox>
              )}
            </TextField>
          )}

          <ButtonField>
            {buttonText2 ? (
              <>
                <Button onClick={onClose}>{buttonText1}</Button>
                <Divider />
                <Button onClick={onClick}>{buttonText2}</Button>
              </>
            ) : (
              <Button onClick={onClick}>{buttonText1}</Button>
            )}
          </ButtonField>
        </ModalField>
      </DimmedWrapper>
    </Portal>
  );
}

const ModalField = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 50%;
  top: ${({ isOpen }) => (isOpen ? '50%' : '100%')};
  transform: ${({ isOpen }) => (isOpen ? 'translate(-50%, -50%)' : 'translate(-50%, 100%)')};
  background-color: ${COLORS.WHITE};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100vw - 60px);
  overflow: hidden;
  z-index: ${LEVEL.MODAL_BASIC};
  transition: all 200ms ease;
`;

const TextField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 52px 0px 46px;
  row-gap: 8px;
`;

const HeadText = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.5px;
  text-align: center;
  white-space: pre-wrap;
`;

const NameBox = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 160%;
  color: ${COLORS.PRIMITIVES_GRAY_500};
  width: 100%;
  white-space: pre-wrap;
  text-align: center;
`;

const Name = styled.span<{ memberName: string }>`
  font-size: 12px;
  font-weight: 700;
  color: ${COLORS.PRIMITIVES_GRAY_600};

  ${({ memberName }) =>
    memberName.length > 10 &&
    css`
      display: inline-block;
      align-items: center;
      max-width: 116px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap !important;
      transform: translateY(5px);
    `}
`;

const ButtonField = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 46px;
`;

const Button = styled.button`
  color: ${COLORS.BLACK};
  padding-top: 2px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.5px;
  cursor: pointer;
`;

const Divider = styled.div`
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 0;
  width: 1px;
  height: 18px;
  background-color: ${COLORS.PRIMITIVES_GRAY_BLUE_300};
`;
