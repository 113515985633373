import { getCurrentQuarter } from '@utils/timeUtil';
import dayjs from 'dayjs';

const useGroupBattleSeasonEndCheck = () => {
  const isSeasonEnd = () => {
    const now = dayjs().tz('Asia/Seoul');
    const seasonStartDate = '2024-10-01';
    // TODO: 그룹 배틀 시즌제 도입하면서 첫 시즌이 8월 중순쯤 시작 ~12월 종료로 정해져서
    // 새 시즌 시작날짜가 2025년 1월이 되어야 하는데
    // 8월 시작을 seasonStartDate로 두면 다음 분기는 10,11,12월인 4분기가 되므로 기획에 맞지 않아서
    // 프론트에서 하드코딩으로 seasonStartDate를 10월로 설정.
    // 다음 시즌부터는 분기별로 시즌을 나눠야함 (추후에 api로 seasonStartDate 받아와서 넣어줘야함)
    const currentQuarter = getCurrentQuarter(seasonStartDate);
    const currentDayjs = dayjs(seasonStartDate).tz('Asia/Seoul');
    // 이번 분기의 다음 달
    const nextQuarterFirstMonth = (currentQuarter % 4) * 3 + 1;
    // 다음 분기가 1분기면 연도 증가
    const nextQuarterFirstYear =
      currentQuarter === 4 ? currentDayjs.year() + 1 : currentDayjs.year();
    // 이번 분기의 다음 달 첫 날
    const nextQuarterFirstDay = dayjs(seasonStartDate)
      .tz('Asia/Seoul')
      .year(nextQuarterFirstYear)
      .month(nextQuarterFirstMonth - 1)
      .startOf('month');
    // 매 분기의 다음 달 첫 날 00:00 ~ 06:00 사이에는 시즌 종료 화면 렌더링
    const startOfNextQuarterFirstDay = nextQuarterFirstDay.startOf('day');
    const endOfNextQuarterFirstDay = nextQuarterFirstDay.startOf('day').add(6, 'hour');
    const isInNextQuarterFirstDayEarlyHours =
      now.isAfter(startOfNextQuarterFirstDay) && now.isBefore(endOfNextQuarterFirstDay);

    return isInNextQuarterFirstDayEarlyHours;
  };
  return { isSeasonEnd };
};

export default useGroupBattleSeasonEndCheck;
