import { getMyRoomPurchasedItemList } from '@apis/myRoomApi';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import { myRoomCurrentSubCategoryState } from '@states/myRoomState';
import { myRoomLikeCountListState, myRoomLikeListState } from '@states/StoreState';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';

const useMyRoomPurchasedItemListQuery = () => {
  const { memberIdx } = useUserInfoQuery();
  const currentSubCategory = useRecoilValue(myRoomCurrentSubCategoryState);
  const setLikeList = useSetRecoilState(myRoomLikeListState);
  const setLikeCountList = useSetRecoilState(myRoomLikeCountListState);

  const query = useQuery({
    queryKey: ['myRoom', 'itemList', 'purchased', currentSubCategory, memberIdx],
    queryFn: () =>
      getMyRoomPurchasedItemList(
        memberIdx,
        currentSubCategory === '전체' ? '' : currentSubCategory,
      ),
  });

  const myRoomList = useMemo(() => query.data ?? [], [query.data]);

  useEffect(() => {
    if (myRoomList.length !== 0) {
      const likeList = myRoomList
        ?.filter(({ liked }) => liked === 1)
        .map(({ room_item_idx }) => room_item_idx);

      const likeCountList = new Map();

      for (const { room_item_idx, likes } of myRoomList) {
        likeCountList.set(room_item_idx, likes);
      }

      setLikeList(new Set(likeList));
      setLikeCountList(likeCountList);
    }
  }, [myRoomList]);

  return { ...query, myRoomList };
};

export default useMyRoomPurchasedItemListQuery;
