import { getCookie } from '@utils/cookie';
import { useQuery } from 'react-query';
import { getUserConfig } from '@apis/profileApi';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import { useMemo } from 'react';
import { ACCESS_TOKEN } from '@shared/link';

const useConfigQuery = () => {
  const userKey = getCookie(ACCESS_TOKEN);

  const { data: userInfo } = useUserInfoQuery();

  const { data, ...queryState } = useQuery(
    ['config', userKey],
    () => getUserConfig(userInfo?.member_idx ?? 0),
    {
      suspense: true,
    },
  );

  const selectedTheme = useMemo(() => {
    return data?.theme;
  }, [data]);

  const excludeAges = useMemo(() => data?.exclude_ages ?? [], [data]);

  const userProfileImage = useMemo(() => data?.profile_img_boards ?? '', [data]);

  return {
    data,
    selectedTheme,
    excludeAges,
    userProfileImage,
    ...queryState,
  };
};

export default useConfigQuery;
