import { createStyles, makeStyles } from '@material-ui/core';

const PC_OS = 'Win16|Win32|Win64|Mac|MacIntel';

const check = () => {
  console.log(navigator);
  if (navigator.platform.includes('iPhone') || navigator.platform.includes('iPad')) {
    return 'ios';
  }
  return 'android';
};

const deviceOs =
  navigator.platform.includes('iPhone') || navigator.platform.includes('iPad') ? 'ios' : 'android';

const safeArea = makeStyles(() =>
  createStyles({
    block: {
      paddingBottom: 'calc(env(safe-area-inset-bottom) - 20px)',
    },
    space: {
      paddingBottom: 100,
    },
  }),
);

const isBrowser = (window as any)?.ReactNativeWebView === undefined;

export { PC_OS, deviceOs, isBrowser, safeArea, check };
