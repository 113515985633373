import Skeleton from '@components/Skeleton';
import styled from 'styled-components';

interface Props {
  padding: string;
}

const RoomSkeleton = ({ padding }: Props) => {
  return (
    <RoomField padding={padding}>
      <Room>
        <Skeleton height="100%" width="100%" />
      </Room>
    </RoomField>
  );
};

export default RoomSkeleton;

const RoomField = styled.div<{ padding: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 56px - 80px);
  padding: ${({ padding }) => padding};
  overflow-y: auto;
  z-index: 1000;
`;

const Room = styled.div`
  width: 100%; /* 가로를 100%로 설정 */
  aspect-ratio: 375 / 488; /* 가로 375px, 세로 488px 비율 */
  height: auto;
`;
