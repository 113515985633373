import { QuestionTooltip } from '@components/elements/QuestionTooltip';
import { COLORS } from '@styles/constants/_colors';
import styled from 'styled-components';

interface Props {
  themeColor?: string;
}

const TodayNutrientsQuestionTooltip = ({ themeColor = '#69B9C4' }: Props) => {
  return (
    <QuestionTooltip
      place="bottom-start"
      topPadding={6}
      message={
        <Contents>
          <div>
            <Title>칼로리 보는 법</Title>
            <Description>
              섭취한 칼로리에서 운동으로 소모한 칼
              <br />
              로리를 뺀 후, 나의 목표 칼로리와 비교
              <br />
              해요.
            </Description>
          </div>
          <div>
            <Title>
              순탄수란<span>?</span>
            </Title>
            <Description>
              탄수화물 총 섭취량에서 체내에 흡수되
              <br />
              지 않는 식이섬유와 대체 감미료 (알룰로
              <br />
              스, 당알콜 등) 섭취량을 뺀 값을 '순탄
              <br />
              수'라고 해요. 인아웃은 정확한 다이어트
              <br />
              지침을 위해 순탄수 기준으로 탄수화물
              <br />
              섭취량을 표시합니다.
            </Description>
          </div>
        </Contents>
      }
      backgroundColor={themeColor}
      color={'rgba(255, 255, 255, 0.2)'}
    />
  );
};

export default TodayNutrientsQuestionTooltip;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Title = styled.p`
  margin-bottom: 8px;

  font-family: Noto Sans KR;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.5px;
  text-align: left;

  color: ${COLORS.BLACK};

  span {
    font-family: Campton;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.5px;
  }
`;

const Description = styled.p`
  font-family: Noto Sans KR;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.5px;

  color: ${COLORS.PRIMITIVES_GRAY_600};
`;
