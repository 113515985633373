import { useLayoutEffect, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { mySubCategory } from '@pages/my/constants/mySubCategory';
import useMyRoomSubCategoryQuery from '@pages/my/hooks/useMyRoomSubCategoryQuery';
import {
  myRoomCurrentMainCategoryState,
  myRoomCurrentSubCategoryState,
  myRoomItemListBottomSheetScrollState,
} from '@states/myRoomState';
import { COLORS } from '@styles/constants/_colors';

const SubCategoryList = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const [scroll, setScroll] = useRecoilState(myRoomItemListBottomSheetScrollState);
  const currentMainCategory = useRecoilValue(myRoomCurrentMainCategoryState);
  const [currentSubCategory, setCurrentSubCategory] = useRecoilState(myRoomCurrentSubCategoryState);
  const { subCategoryList } = useMyRoomSubCategoryQuery(
    currentMainCategory === '마이' || currentMainCategory === '좋아요' ? '' : currentMainCategory,
  );

  const handleClickSubCategory = (tab: string) => {
    queryClient.removeQueries({ queryKey: ['myRoom', 'itemList'], exact: false });
    setCurrentSubCategory(tab);
    setScroll((prev) => ({
      ...prev,
      scrollY: {
        purchased: 0,
        liked: 0,
        categories: 0,
      },
    }));
  };

  useLayoutEffect(() => {
    if (!wrapperRef.current) return;
    wrapperRef.current.scrollTo(scroll.subCategoryScrollX, 0);

    return () => {
      if (!wrapperRef.current) return;
      setScroll((prev) => ({
        ...prev,
        subCategoryScrollX: wrapperRef.current?.scrollLeft ?? 0,
      }));
    };
  }, [setScroll, currentMainCategory]);

  return (
    <Wrapper ref={wrapperRef}>
      <TabField height={currentMainCategory === '좋아요' ? '20px' : '49px'}>
        {currentMainCategory === '마이' &&
          mySubCategory.map((sub) => (
            <Category
              key={sub}
              isSelected={currentSubCategory === sub}
              onClick={() => handleClickSubCategory(sub)}
            >
              {sub}
            </Category>
          ))}
        {currentMainCategory !== '마이' &&
          currentMainCategory !== '좋아요' &&
          subCategoryList.map((sub) => (
            <Category
              key={sub.value}
              isSelected={currentSubCategory === sub.value}
              onClick={() => handleClickSubCategory(sub.value)}
            >
              {sub.value}
            </Category>
          ))}
      </TabField>
    </Wrapper>
  );
};

export default SubCategoryList;

const Wrapper = styled.div`
  position: absolute;
  top: 124px;
  background-color: ${COLORS.PRIMITIVES_GRAY_60};
  width: 100%;
  height: max-content;
  overflow-x: auto;
  z-index: 1000;
`;

const TabField = styled.div<{ height: string }>`
  width: min-content;
  height: ${({ height }) => height};
  padding: 0px 20px;
  display: flex;
  align-items: center;
  column-gap: 17px;
`;

const Category = styled.p<{ isSelected: boolean }>`
  color: ${({ isSelected }) => (isSelected ? COLORS.BLACK : COLORS.PRIMITIVES_GRAY_450)};
  font-size: 14px;
  font-weight: ${({ isSelected }) => (isSelected ? 700 : 500)};
  line-height: 120%;
  letter-spacing: -0.5px;
  white-space: nowrap;
`;
