import BackArrowBtn from '@components/Molecules/BackArrowBtn';
import Skeleton from '@components/Skeleton';
import { COLORS } from '@styles/constants/_colors';
import LEVEL from '@styles/constants/_levels';
import styled from 'styled-components';
import RoomSkeleton from './RoomSkeleton';

interface Props {
  padding: string;
}

const MyRoomEditorSkeleton = ({ padding }: Props) => {
  return (
    <>
      <Header className="editor-menu">
        <RoomInfoField>
          <BackArrowBtn />
          <Skeleton width={40} height={40} radius="50%" />
          <InfoBox>
            <Skeleton width={91} height={15} radius={120} margin="0px 0px 2px 0px" />
            <Skeleton width={32} height={10} radius={120} />
          </InfoBox>
        </RoomInfoField>
        <Skeleton width={62} height={36} radius={120} />
      </Header>
      <Room>
        <RoomSkeleton padding={padding} />
      </Room>
      <Bottom>
        <Left>
          <Col>
            <Skeleton width={40} height={40} radius="50%" margin="0px 0px 6px 0px" />
            <Skeleton width={40} height={10} radius={120} />
          </Col>
          <Col>
            <Skeleton width={40} height={40} radius="50%" margin="0px 0px 6px 0px" />
            <Skeleton width={40} height={10} radius={120} />
          </Col>
        </Left>
        <Skeleton width="100%" height={56} radius={120} />
      </Bottom>
    </>
  );
};

export default MyRoomEditorSkeleton;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 18px 0px 20px;
  width: 100%;
  max-width: 428px;
  height: 56px;
  position: fixed;
  top: 0;
  background-color: ${COLORS.WHITE};
  z-index: ${LEVEL.MODAL_BASIC};
`;

const RoomInfoField = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

const Room = styled.div`
  display: flex;
  align-items: center;
  padding: 56px 0px 80px;
  min-height: 100vh;
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  width: 100%;
  max-width: 428px;
  height: 80px;
  padding: 0px 12px 0px 27px;
  background-color: ${COLORS.WHITE};
  position: fixed;
  bottom: 0;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  column-gap: 32px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
