import { useQuery } from 'react-query';
import { getMyRoomMainCategoryList } from '@apis/storeApi';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import { useMemo } from 'react';

const useMyRoomMainCategoryQuery = () => {
  const { memberIdx } = useUserInfoQuery();

  const query = useQuery(
    ['myRoom', 'mainCategory', memberIdx],
    () => getMyRoomMainCategoryList(memberIdx),
    {
      select: (data) => data.filter(({ active }) => active === 1),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      keepPreviousData: true,
      staleTime: Infinity,
    },
  );

  const mainCategoryList = useMemo(
    () => query.data?.map(({ icon, title }) => ({ icon, value: title })) ?? [],
    [query.data],
  );

  return {
    ...query,
    mainCategoryList,
  };
};

export default useMyRoomMainCategoryQuery;
