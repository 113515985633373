import usePostMessageReceiver from '@hooks/usePostMessageReceiver';
import useModal from '@hooks/useModal';
import { useEffect, useRef } from 'react';
import Popup from '@components/elements/Popup/Popup';
import { useNavigate } from 'react-router-dom';

const useEditorBackPressHandling = () => {
  const navigate = useNavigate();
  const { hasModal, openModal, closeModal, closeLastModal } = useModal();
  const hasModalRef = useRef(false);

  usePostMessageReceiver((event: any) => {
    const { type } = JSON.parse(event.data);

    if (type === 'back-button-press') {
      // handle back button press
      if (hasModalRef.current) {
        closeLastModal();
      } else {
        openModal(Popup, {
          buttonText1: '아니요',
          buttonText2: '네',
          headText: '방 꾸미기를 정말 그만두시겠어요?',
          subText1: '저장하지 않고 그만두면 꾸민 내용이 모두 사라져요',
          onClick: () => {
            navigate(-1);
            closeModal(Popup);
          },
          cancel: () => {
            closeModal(Popup);
          },
        });
      }
    }
  });

  useEffect(() => {
    hasModalRef.current = hasModal;
  }, [hasModal]);
};

export default useEditorBackPressHandling;
