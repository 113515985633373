import { getMyNewsCount } from '@apis/mainApi';
import { useQuery } from 'react-query';

const useGetMyNewsCountQuery = () => {
  return useQuery({
    queryKey: ['myNewsCount'],
    queryFn: getMyNewsCount,
  });
};

export default useGetMyNewsCountQuery;
