import { useEffect } from 'react';

const useStopBodyScroll = () => {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow: hidden;
      width: 100%;
      ::-webkit-scrollbar {
        display: none;
      }
      `;

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, []);
};

export default useStopBodyScroll;
