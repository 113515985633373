import { GNBCloseIcon } from 'assets/svgs';
import useToastModal from 'hooks/useToastModal';
import SecurityView from 'pages/security/SecurityView';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import usePostMessageReceiver from '@hooks/usePostMessageReceiver';
import { NativeMessage } from '@models/native';
import { sendReactNativeMessage } from '@shared/sendReactNativeMessage';
import useAdBrix from '@hooks/adBrix/useAdBrix';

interface Props {
  onClose: () => void;
}

const SecurityRegisterModal = ({ onClose }: Props) => {
  const [title, setTitle] = useState('비밀번호 입력');
  const [password, setPassword] = useState<number[]>([]);
  const [confirmPassword, setConfirmPassword] = useState<number[]>([]);
  const [isFail, setIsFail] = useState(false);
  const [step, setStep] = useState(1);

  const { sendAdBrixData } = useAdBrix();

  const isMax = password.length === 4;

  const { openModal } = useToastModal();

  const onPostMessage = (message: any) => {
    try {
      (window as any).ReactNativeWebView.postMessage(message);
    } catch (error) {
      console.log(`PostMessage Error: security login`);
    }
  };

  const onSuccess = () => {
    openModal({ children: <p>앱 잠금 설정이 완료되었어요</p> });
    onClose();
    sendAdBrixData('customEvent', {
      eventKey: 'complete_account_lock',
    });
  };

  const onFail = () => {
    setIsFail(true);
    onPostMessage('vibration-fail');
    const reset = setTimeout(() => {
      setConfirmPassword([]);
      setIsFail(false);
      clearTimeout(reset);
    }, 400);
  };

  const handlePassword = () => {
    return (
      {
        1: password,
        2: confirmPassword,
      }[step] ?? password
    );
  };

  const handleSetPassword = () => {
    return (
      {
        1: setPassword,
        2: setConfirmPassword,
      }[step] ?? setPassword
    );
  };

  const handleRegister = () => {
    if (!isMax) return;
    setTitle('비밀번호 한 번 더 입력');
    setStep(2);
  };

  const handleConfirm = async () => {
    const isMax = confirmPassword.length === 4;
    if (!isMax) return;

    const isSuccess = password.every((value, idx) => value === confirmPassword[idx]);

    if (!isSuccess) {
      onFail();
      return;
    }

    sendReactNativeMessage({
      type: 'security-lock-create',
      payload: {
        password: confirmPassword.join(''),
      },
    });
  };

  usePostMessageReceiver((event: NativeMessage) => {
    const { type } = JSON.parse(event.data);

    if (type === 'security-lock-create-finish') {
      onSuccess();
    }
  });

  useEffect(() => {
    handleRegister();
  }, [password]);

  useEffect(() => {
    handleConfirm();
  }, [confirmPassword]);

  return (
    <>
      <Header>
        <GNBCloseIcon onClick={onClose} />
      </Header>
      <SecurityView
        title={title}
        password={handlePassword()}
        setPassword={handleSetPassword()}
        isFail={isFail}
      />
    </>
  );
};

const Header = styled.header`
  position: fixed;
  width: 100%;
  max-width: 428px;
  top: 0;
  padding: 17px 20px;
`;

export default SecurityRegisterModal;
