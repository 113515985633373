import { useRecoilState } from 'recoil';
import { setChangeDate } from '../../../modules/date';
import { dateState } from '../../../states/DateState';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { COLORS } from '@styles/constants/_colors';
import {
  FridayIcon,
  MondayIcon,
  SaturdayIcon,
  SundayIcon,
  ThursdayIcon,
  TodayIcon,
  TuesdayIcon,
  WednesdayIcon,
} from '@assets/svgs/main';
import { useEffect, useRef, useState } from 'react';
import SwiperRef from 'swiper';
import dayjs from 'dayjs';
import useAdBrix from '@hooks/adBrix/useAdBrix';

const DayIcons = [
  <SundayIcon />,
  <MondayIcon />,
  <TuesdayIcon />,
  <WednesdayIcon />,
  <ThursdayIcon />,
  <FridayIcon />,
  <SaturdayIcon />,
];

const DayTexts = ['일', '월', '화', '수', '목', '금', '토'];

export default function MainDateNavigation() {
  const swiperRef = useRef<SwiperRef | null>(null);
  const [date, setDate] = useRecoilState(dateState);

  const { sendAdBrixData } = useAdBrix();

  const [selectedIdx, setSelectedIdx] = useState(-1);
  const [slides, setSlides] = useState<string[]>([]);
  const initialLoading = useRef(true); // 첫 세팅 이동 방지
  const slideLoading = useRef(false); // 슬라이더 이동 시 세팅 방지

  const swiperOptions: SwiperProps = {
    slidesPerView: 3,
    spaceBetween: 80,
    initialSlide: 1,
    centeredSlides: true,
    slideToClickedSlide: true,
    onSwiper: (ref: SwiperRef) => {
      swiperRef.current = ref;
    },
    onSlideNextTransitionStart: (ref: SwiperRef) => {
      if (initialLoading.current) return;

      slideLoading.current = true;
      moveToNextDate(ref.activeIndex - ref.previousIndex);
    },
    onSlidePrevTransitionStart: (ref: SwiperRef) => {
      if (initialLoading.current) return;

      slideLoading.current = true;
      moveToBeforeDate(ref.activeIndex - ref.previousIndex);
    },
    onSlideChangeTransitionEnd: (ref: SwiperRef) => {
      if (initialLoading.current) return;
      slideLoading.current = false;
      setSelectedIdx(ref.activeIndex);
      sendAdBrixData('customEvent', {
        eventKey: 'swipe_home',
      });
    },
  };

  const slideInitialSetting = () => {
    initialLoading.current = true;

    const slideInfo = Array(100)
      .fill('')
      .map((_, index) => setChangeDate(date, 'day', index - 50, 'M.D'));
    setSlides(slideInfo);

    swiperRef?.current?.slideTo(50, 0);
    setSelectedIdx(50);
    swiperRef?.current?.slideTo(50); // slide 스피드 초기화

    initialLoading.current = false;
  };

  const moveToBeforeDate = (changeDays: number) => {
    // 뒤로가기의 경우 배열 정보가 변경되면서 애니메이션 스와이프와 맞지 않는 현상으로 인해 처음부터 배열 크기를 늘리고 따로 추가는 진행하지 않고 있습니다.
    setDate(setChangeDate(date, 'day', changeDays));
  };

  const moveToNextDate = (changeDays: number) => {
    if (selectedIdx + 10 > slides.length) {
      setSlides((prev) => [...prev, setChangeDate(prev[prev.length - 1], 'day', 1, 'M.D')]);
    }

    setDate(setChangeDate(date, 'day', changeDays));
  };

  const renderDays = (renderDate: string, idx: number) => {
    const currentYear = dayjs().year(); // 현재 연도 가져오기
    const formattedDate = `${currentYear}-${renderDate.replace('.', '-')}`; // MM.DD → YYYY-MM-DD로 변환

    if (selectedIdx !== idx) return <DayUnit>{DayTexts[dayjs(formattedDate).day()]}</DayUnit>;

    if (dayjs().format('M.D') === renderDate) return <TodayIcon />;

    return DayIcons[dayjs(formattedDate).day()];
  };

  useEffect(() => {
    if (slideLoading.current) return;

    slideInitialSetting();
  }, [date]);

  return (
    <Wrapper>
      <GradientLeftUnit onClick={() => swiperRef.current?.slideTo(selectedIdx - 1)} />
      <Swiper {...swiperOptions}>
        {slides.map((slideContent, idx) => (
          <SwiperSlide key={slideContent}>
            <SlideBox>
              <DateUnit $selected={selectedIdx === idx}>{slideContent}</DateUnit>
              {renderDays(slideContent, idx)}
            </SlideBox>
          </SwiperSlide>
        ))}
      </Swiper>
      <GradientRightUnit onClick={() => swiperRef.current?.slideTo(selectedIdx + 1)} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 52px;
  position: relative;
  overflow: hidden;

  &.swiper-slide-active {
    span {
      color: ${COLORS.BLACK};
    }
  }
`;

const GradientLeftUnit = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  width: 57px;
  height: 100%;
  background: linear-gradient(90deg, #fff 33.33%, rgba(255, 255, 255, 0) 89.47%);
  z-index: 1;
`;

const GradientRightUnit = styled.div`
  top: 0;
  right: 0;
  position: absolute;
  width: 57px;
  height: 100%;
  background: linear-gradient(270deg, #fff 33.33%, rgba(255, 255, 255, 0) 89.47%);
  z-index: 1;
`;

const SlideBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  width: 100%;
  height: 52px;

  > svg {
    min-width: fit-content;
  }
`;

const DateUnit = styled.span<{ $selected: boolean }>`
  color: ${({ $selected }) => ($selected ? COLORS.BLACK : COLORS.PRIMITIVES_GRAY_350)};
  font-family: Campton;
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.5px;
`;

const DayUnit = styled.div`
  font-family: 'Noto Sans KR';
  color: ${COLORS.PRIMITIVES_GRAY_350};
  font-weight: 700;
  font-size: 16px;
  line-height: 112.5%;
  letter-spacing: -0.615px;
`;
