import { useRef } from 'react';
import { useRecoilState } from 'recoil';
import { myRoomLikeCountListState, myRoomLikeListState } from '@states/StoreState';
import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import { useMutation } from 'react-query';
import { patchMyRoomLike, patchMyRoomLikeCancel } from '@apis/storeApi';
import useAdBrix from '@hooks/adBrix/useAdBrix';

const useMyRoomLike = () => {
  const likeRef = useRef(false);
  const { sendAdBrixData } = useAdBrix();
  const [selectedLike, setSelectedLike] = useRecoilState(myRoomLikeListState);
  const [likeCountList, setLikeCountList] = useRecoilState(myRoomLikeCountListState);

  const { memberIdx } = useUserInfoQuery();

  const { mutateAsync: addLike } = useMutation(
    (roomItemIdx: number) => patchMyRoomLike(memberIdx, roomItemIdx),
    {
      onSuccess: (roomItemIdx) => {
        setSelectedLike((prev) => {
          const next = new Set(prev);
          next.add(roomItemIdx);
          return next;
        });

        setLikeCountList((prev) => {
          const next = new Map(prev);
          next.set(roomItemIdx, (next.get(roomItemIdx) ?? 0) + 1);
          return next;
        });

        likeRef.current = false;
      },
    },
  );

  const { mutateAsync: removeLike } = useMutation(
    (roomItemIdx: number) => patchMyRoomLikeCancel(memberIdx, roomItemIdx),
    {
      onSuccess: (roomItemIdx) => {
        setSelectedLike((prev) => {
          const next = new Set(prev);
          next.delete(roomItemIdx);
          return next;
        });

        setLikeCountList((prev) => {
          const next = new Map(prev);
          next.set(roomItemIdx, (next.get(roomItemIdx) ?? 0) - 1);
          return next;
        });

        likeRef.current = false;
      },
    },
  );

  const isLike = (roomItemIdx: number) => selectedLike.has(roomItemIdx);
  const getLikeCount = (roomItemIdx: number) => likeCountList.get(roomItemIdx) ?? 0;

  const onLikeClick = async (roomItemIdx: number, name: string) => {
    if (likeRef.current) return;
    likeRef.current = true;
    sendAdBrixData('customEvent', {
      eventKey: 'click_store_like_quick',
      property: {
        item_title: name,
      },
    });

    if (isLike(roomItemIdx)) {
      removeLike(roomItemIdx);
    } else {
      addLike(roomItemIdx).then(() => {
        sendAdBrixData('customEvent', {
          eventKey: 'register_item_like',
          property: {
            item_title: name,
          },
        });
      });
    }
  };

  return {
    like: selectedLike,
    setLike: setSelectedLike,
    addLike,
    removeLike,
    isLike,
    getLikeCount,
    onLikeClick,
  };
};

export default useMyRoomLike;
