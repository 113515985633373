import React from 'react';
import styled from 'styled-components';
import { noneDraggable } from '@styles/mixins/_extends';
import LikeIcon from '@assets/svgs/_Icons/LikeIcon';
import { COLORS, FOUNDATION_COLOR } from '@styles/constants/_colors';

interface Props {
  count: number;
  active?: boolean;
  onClick?: () => void;
}

const LikeCount = ({ count = 0, active, onClick }: Props) => {
  const likeTextColor = active ? FOUNDATION_COLOR.PRIMARY_PINK : COLORS.PRIMITIVES_GRAY_450;

  return (
    <Wrapper $color={likeTextColor} onClick={onClick}>
      <LikeButton size="m" width={26} height={26} active={active} />
      <span>{count}</span>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $color: string }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  color: ${({ $color }) => $color};
  text-align: right;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.5px;

  ${noneDraggable};

  > span {
    padding-bottom: 1px;
  }
`;

const LikeButton = styled(LikeIcon)`
  width: 24px;
  height: 24px;

  cursor: pointer;
`;

export default LikeCount;
