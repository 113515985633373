import { useLocation, useNavigate } from 'react-router-dom';
import useGroupBattleSeasonEndCheck from '../hooks/useGroupBattleSeasonEndCheck';
import { useEffect } from 'react';

interface Props {
  children: React.ReactNode;
}

const GroupBattleSeasonEndCheckWrapper = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isSeasonEnd } = useGroupBattleSeasonEndCheck();

  useEffect(() => {
    if (isSeasonEnd() && location.pathname !== '/battle/group') {
      navigate('/battle/group');
    }
  }, [isSeasonEnd, location.pathname, navigate]);

  return <>{children}</>;
};
export default GroupBattleSeasonEndCheckWrapper;
