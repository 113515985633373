const LEVEL = {
  COMMUNICATIONS_HEADER: 1000,
  COMMUNICATIONS_SUB_HEADER: 1001,
  BACKGROUND_MODAL: 2499,
  MODAL_HEADER: 2501,
  MODAL_BASIC: 2500,
  BUTTON_NAV: 2500,
  FLOAT_MODAL: 2500,
  PROMOTION_MODAL: 3000,
  SECURITY_MODAL: 10000,
  FORCE_MODAL: 11000,
  SPLASH_MODAL: 20000,
};

export default LEVEL;
