import { atom } from 'recoil';

export type textNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export const toastMsgTextState = atom<textNumber>({
  key: 'toastMsgTextState',
  default: 0,
});

export const updatePopUpState = atom<boolean>({
  key: 'updatePopUpState',
  default: false,
});

export const indexPopUpState = atom<boolean>({
  key: 'indexPopUpState',
  default: false,
});

export const spinnerState = atom<boolean>({
  key: 'spinnerState',
  default: true,
});

export const cautionPopupState = atom<boolean>({
  key: 'cautionPopupState',
  default: false,
});

export const appStatusState = atom<string>({
  key: 'appStatusState',
  default: 'foreground',
});

export const forceState = atom<boolean>({
  key: '#forceState',
  default: false,
});
