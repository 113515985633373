import { useLayoutEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  myRoomCurrentMainCategoryState,
  myRoomCurrentSubCategoryState,
  myRoomItemListBottomSheetScrollState,
} from '@states/myRoomState';

const useItemListScrollRestoration = (
  isLoading: boolean,
  currentComponentName: 'purchased' | 'liked' | 'categories',
) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [scroll, setScroll] = useRecoilState(myRoomItemListBottomSheetScrollState);
  const currentMainCategory = useRecoilValue(myRoomCurrentMainCategoryState);
  const currentSubCategory = useRecoilValue(myRoomCurrentSubCategoryState);

  useLayoutEffect(() => {
    if (isLoading) return;
    if (!wrapperRef.current) return;
    wrapperRef.current.scrollTo(0, scroll.scrollY[currentComponentName]);

    return () => {
      if (!wrapperRef.current) return;
      setScroll((prev) => ({
        ...prev,
        scrollY: {
          ...prev.scrollY,
          [currentComponentName]: wrapperRef.current?.scrollTop ?? 0,
        },
      }));
    };
  }, [isLoading, setScroll, currentMainCategory, currentSubCategory]);

  return {
    wrapperRef,
  };
};

export default useItemListScrollRestoration;
