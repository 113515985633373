import classNames from 'classnames';
import styled from 'styled-components';
import Menu from '../assets/images/Navigation/Menu';
import { deviceOs, safeArea } from '../modules/platformCheck';
import { useLocation } from 'react-router-dom';

export default function Navigation() {
  const location = useLocation();

  const iterator = Array.from({ length: 5 }, () => 0);
  const selectedIndex = (() => {
    let path = location.pathname.split('/')[1];
    if (path === 'communication' && location.pathname.split('/')[2] === 'ketogram') {
      path = 'ketogram';
    }

    switch (path) {
      case '':
      case 'fasting':
      case 'statistics':
      case 'battle':
        return 0;
      case 'myPage':
        return 1;
      case 'apple':
        return 2;
      case 'ketogram':
        return 3;
      case 'communication':
        return 4;
    }
  })();

  return (
    <MainBottomGnbContainer className={classNames([deviceOs === 'ios' && safeArea().block])}>
      {iterator.map((_, index) => (
        <Menu key={index} index={index} isSelected={index === selectedIndex} />
      ))}
    </MainBottomGnbContainer>
  );
}

const MainBottomGnbContainer = styled.div`
  display: flex;
  gap: 6px;
  width: 100%;
  max-width: 428px;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 1001;
  padding: 0 16px;
`;
