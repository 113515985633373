import useUserInfoQuery from '@hooks/queries/useUserInfoQuery';
import { useQuery } from 'react-query';
import { getMyRoomSubCategoryList } from '@apis/storeApi';
import { useMemo } from 'react';

const useMyRoomSubCategoryQuery = (mainCategory: string) => {
  const { memberIdx } = useUserInfoQuery();
  const query = useQuery(
    ['myRoom', mainCategory, 'subCategory', memberIdx],
    () => getMyRoomSubCategoryList(memberIdx, mainCategory),
    {
      enabled: !!mainCategory,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      keepPreviousData: true,
      staleTime: Infinity,
    },
  );

  const subCategoryList = useMemo(
    () => query.data?.map(({ type }) => ({ value: type })) ?? [],
    [query.data],
  );

  return {
    ...query,
    subCategoryList,
  };
};

export default useMyRoomSubCategoryQuery;
